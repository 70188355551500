import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import VueHead from "vue-head"
Vue.use(VueHead);

import GlobalComponents from "../src/components"


Vue.config.productionTip = false

/* eslint-disable */
window.bootstrapApp = new Vue({
  components: {
    GlobalComponents
  },
  router,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
