<template>
  <div class="body">
    <header
      class="bd-header bg-dark py-3 d-flex align-items-stretch border-bottom border-dark"
    >
      <div class="container-fluid d-flex align-items-center">
        <h1 class="d-flex align-items-center fs-4 text-white mb-0">
          <img
            src="https://getbootstrap.com/docs/5.0/assets/brand/bootstrap-logo-white.svg"
            width="38"
            height="30"
            class="me-3"
            alt="Bootstrap"
          />
          Cheatsheet
        </h1>
        <a
          href="https://getbootstrap.com/docs/5.0/examples/cheatsheet-rtl/"
          class="ms-auto link-light"
          hreflang="ar"
          >RTL cheatsheet</a
        >
      </div>
    </header>
    <aside
      class="bd-aside sticky-xl-top text-muted align-self-start mb-3 mb-xl-5 px-2"
    >
      <h2 class="h6 pt-4 pb-3 mb-4 border-bottom">On this page</h2>
      <nav class="small" id="toc">
        <ul class="list-unstyled">
          <li class="my-2">
            <button
              class="btn d-inline-flex align-items-center collapsed"
              data-bs-toggle="collapse"
              aria-expanded="false"
              data-bs-target="#contents-collapse"
              aria-controls="contents-collapse"
            >
              Contents
            </button>
            <ul class="list-unstyled ps-3 collapse" id="contents-collapse">
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#typography"
                  >Typography</a
                >
              </li>
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#images"
                  >Images</a
                >
              </li>
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#tables"
                  >Tables</a
                >
              </li>
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#figures"
                  >Figures</a
                >
              </li>
            </ul>
          </li>
          <li class="my-2">
            <button
              class="btn d-inline-flex align-items-center collapsed"
              data-bs-toggle="collapse"
              aria-expanded="false"
              data-bs-target="#forms-collapse"
              aria-controls="forms-collapse"
            >
              Forms
            </button>
            <ul class="list-unstyled ps-3 collapse" id="forms-collapse">
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#overview"
                  >Overview</a
                >
              </li>
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#disabled-forms"
                  >Disabled forms</a
                >
              </li>
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#sizing"
                  >Sizing</a
                >
              </li>
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#input-group"
                  >Input group</a
                >
              </li>
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#floating-labels"
                  >Floating labels</a
                >
              </li>
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#validation"
                  >Validation</a
                >
              </li>
            </ul>
          </li>
          <li class="my-2">
            <button
              class="btn d-inline-flex align-items-center collapsed"
              data-bs-toggle="collapse"
              aria-expanded="false"
              data-bs-target="#components-collapse"
              aria-controls="components-collapse"
            >
              Components
            </button>
            <ul class="list-unstyled ps-3 collapse" id="components-collapse">
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#accordion"
                  >Accordion</a
                >
              </li>
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#alerts"
                  >Alerts</a
                >
              </li>
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#badge"
                  >Badge</a
                >
              </li>
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#breadcrumb"
                  >Breadcrumb</a
                >
              </li>
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#buttons"
                  >Buttons</a
                >
              </li>
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#button-group"
                  >Button group</a
                >
              </li>
              <li>
                <a class="d-inline-flex align-items-center rounded" href="#card"
                  >Card</a
                >
              </li>
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#carousel"
                  >Carousel</a
                >
              </li>
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#dropdowns"
                  >Dropdowns</a
                >
              </li>
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#list-group"
                  >List group</a
                >
              </li>
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#modal"
                  >Modal</a
                >
              </li>
              <li>
                <a class="d-inline-flex align-items-center rounded" href="#navs"
                  >Navs</a
                >
              </li>
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#navbar"
                  >Navbar</a
                >
              </li>
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#pagination"
                  >Pagination</a
                >
              </li>
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#popovers"
                  >Popovers</a
                >
              </li>
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#progress"
                  >Progress</a
                >
              </li>
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#scrollspy"
                  >Scrollspy</a
                >
              </li>
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#spinners"
                  >Spinners</a
                >
              </li>
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#toasts"
                  >Toasts</a
                >
              </li>
              <li>
                <a
                  class="d-inline-flex align-items-center rounded"
                  href="#tooltips"
                  >Tooltips</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </aside>
    <div class="bd-cheatsheet container-fluid bg-body">
      <section id="content">
        <h2 class="sticky-xl-top fw-bold pt-3 pt-xl-5 pb-2 pb-xl-3">
          Contents
        </h2>

        <article class="my-3" id="typography">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Typography</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/content/typography/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <p class="display-1">Display 1</p>
              <p class="display-2">Display 2</p>
              <p class="display-3">Display 3</p>
              <p class="display-4">Display 4</p>
              <p class="display-5">Display 5</p>
              <p class="display-6">Display 6</p>
            </div>

            <div class="bd-example">
              <p class="h1">Heading 1</p>
              <p class="h2">Heading 2</p>
              <p class="h3">Heading 3</p>
              <p class="h4">Heading 4</p>
              <p class="h5">Heading 5</p>
              <p class="h6">Heading 6</p>
            </div>

            <div class="bd-example">
              <p class="lead">
                This is a lead paragraph. It stands out from regular paragraphs.
              </p>
            </div>

            <div class="bd-example">
              <p>You can use the mark tag to <mark>highlight</mark> text.</p>
              <p>
                <del
                  >This line of text is meant to be treated as deleted
                  text.</del
                >
              </p>
              <p>
                <s
                  >This line of text is meant to be treated as no longer
                  accurate.</s
                >
              </p>
              <p>
                <ins
                  >This line of text is meant to be treated as an addition to
                  the document.</ins
                >
              </p>
              <p><u>This line of text will render as underlined.</u></p>
              <p>
                <small
                  >This line of text is meant to be treated as fine
                  print.</small
                >
              </p>
              <p><strong>This line rendered as bold text.</strong></p>
              <p><em>This line rendered as italicized text.</em></p>
            </div>

            <div class="bd-example">
              <blockquote class="blockquote">
                <p>A well-known quote, contained in a blockquote element.</p>
                <footer class="blockquote-footer">
                  Someone famous in
                  <cite title="Source Title">Source Title</cite>
                </footer>
              </blockquote>
            </div>

            <div class="bd-example">
              <ul class="list-unstyled">
                <li>This is a list.</li>
                <li>It appears completely unstyled.</li>
                <li>Structurally, it's still a list.</li>
                <li>
                  However, this style only applies to immediate child elements.
                </li>
                <li>
                  Nested lists:
                  <ul>
                    <li>are unaffected by this style</li>
                    <li>will still show a bullet</li>
                    <li>and have appropriate left margin</li>
                  </ul>
                </li>
                <li>This may still come in handy in some situations.</li>
              </ul>
            </div>

            <div class="bd-example">
              <ul class="list-inline">
                <li class="list-inline-item">This is a list item.</li>
                <li class="list-inline-item">And another one.</li>
                <li class="list-inline-item">But they're displayed inline.</li>
              </ul>
            </div>
          </div>
        </article>
        <article class="my-3" id="images">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Images</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/content/images/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <svg
                class="bd-placeholder-img bd-placeholder-img-lg img-fluid"
                width="100%"
                height="250"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                aria-label="Placeholder: Responsive image"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
              >
                <title>Placeholder</title>
                <rect width="100%" height="100%" fill="#868e96" />
                <text x="50%" y="50%" fill="#dee2e6" dy=".3em">
                  Responsive image
                </text>
              </svg>
            </div>

            <div class="bd-example">
              <svg
                class="bd-placeholder-img img-thumbnail"
                width="200"
                height="200"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                aria-label="A generic square placeholder image with a white border around it, making it resemble a photograph taken with an old instant camera: 200x200"
                preserveAspectRatio="xMidYMid slice"
                focusable="false"
              >
                <title>
                  A generic square placeholder image with a white border around
                  it, making it resemble a photograph taken with an old instant
                  camera
                </title>
                <rect width="100%" height="100%" fill="#868e96" />
                <text x="50%" y="50%" fill="#dee2e6" dy=".3em">200x200</text>
              </svg>
            </div>
          </div>
        </article>
        <article class="my-3" id="tables">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Tables</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/content/tables/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">First</th>
                    <th scope="col">Last</th>
                    <th scope="col">Handle</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td colspan="2">Larry the Bird</td>
                    <td>@twitter</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="bd-example">
              <table class="table table-dark table-borderless">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">First</th>
                    <th scope="col">Last</th>
                    <th scope="col">Handle</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td colspan="2">Larry the Bird</td>
                    <td>@twitter</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="bd-example">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Class</th>
                    <th scope="col">Heading</th>
                    <th scope="col">Heading</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Default</th>
                    <td>Cell</td>
                    <td>Cell</td>
                  </tr>

                  <tr class="table-primary">
                    <th scope="row">Primary</th>
                    <td>Cell</td>
                    <td>Cell</td>
                  </tr>
                  <tr class="table-secondary">
                    <th scope="row">Secondary</th>
                    <td>Cell</td>
                    <td>Cell</td>
                  </tr>
                  <tr class="table-success">
                    <th scope="row">Success</th>
                    <td>Cell</td>
                    <td>Cell</td>
                  </tr>
                  <tr class="table-danger">
                    <th scope="row">Danger</th>
                    <td>Cell</td>
                    <td>Cell</td>
                  </tr>
                  <tr class="table-warning">
                    <th scope="row">Warning</th>
                    <td>Cell</td>
                    <td>Cell</td>
                  </tr>
                  <tr class="table-info">
                    <th scope="row">Info</th>
                    <td>Cell</td>
                    <td>Cell</td>
                  </tr>
                  <tr class="table-light">
                    <th scope="row">Light</th>
                    <td>Cell</td>
                    <td>Cell</td>
                  </tr>
                  <tr class="table-dark">
                    <th scope="row">Dark</th>
                    <td>Cell</td>
                    <td>Cell</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="bd-example">
              <table class="table table-sm table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">First</th>
                    <th scope="col">Last</th>
                    <th scope="col">Handle</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td colspan="2">Larry the Bird</td>
                    <td>@twitter</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </article>
        <article class="my-3" id="figures">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Figures</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/content/figures/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <figure class="figure">
                <svg
                  class="bd-placeholder-img figure-img img-fluid rounded"
                  width="400"
                  height="300"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-label="Placeholder: 400x300"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                >
                  <title>Placeholder</title>
                  <rect width="100%" height="100%" fill="#868e96" />
                  <text x="50%" y="50%" fill="#dee2e6" dy=".3em">400x300</text>
                </svg>

                <figcaption class="figure-caption">
                  A caption for the above image.
                </figcaption>
              </figure>
            </div>
          </div>
        </article>
      </section>

      <section id="forms">
        <h2 class="sticky-xl-top fw-bold pt-3 pt-xl-5 pb-2 pb-xl-3">Forms</h2>

        <article class="my-3" id="overview">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Overview</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/forms/overview/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <form>
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label"
                    >Email address</label
                  >
                  <input
                    type="email"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                  <div id="emailHelp" class="form-text">
                    We'll never share your email with anyone else.
                  </div>
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label"
                    >Password</label
                  >
                  <input
                    type="password"
                    class="form-control"
                    id="exampleInputPassword1"
                  />
                </div>
                <div class="mb-3 form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="exampleCheck1"
                  />
                  <label class="form-check-label" for="exampleCheck1"
                    >Check me out</label
                  >
                </div>
                <fieldset class="mb-3">
                  <legend>Radios buttons</legend>
                  <div class="form-check">
                    <input
                      type="radio"
                      name="radios"
                      class="form-check-input"
                      id="exampleRadio1"
                    />
                    <label class="form-check-label" for="exampleRadio1"
                      >Default radio</label
                    >
                  </div>
                  <div class="mb-3 form-check">
                    <input
                      type="radio"
                      name="radios"
                      class="form-check-input"
                      id="exampleRadio2"
                    />
                    <label class="form-check-label" for="exampleRadio2"
                      >Another radio</label
                    >
                  </div>
                </fieldset>
                <div class="mb-3">
                  <label class="form-label" for="customFile">Upload</label>
                  <input type="file" class="form-control" id="customFile" />
                </div>
                <div class="mb-3 form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    checked
                  />
                  <label class="form-check-label" for="flexSwitchCheckChecked"
                    >Checked switch checkbox input</label
                  >
                </div>
                <div class="mb-3">
                  <label for="customRange3" class="form-label"
                    >Example range</label
                  >
                  <input
                    type="range"
                    class="form-range"
                    min="0"
                    max="5"
                    step="0.5"
                    id="customRange3"
                  />
                </div>
                <button type="submit" class="btn btn-primary">Submit</button>
              </form>
            </div>
          </div>
        </article>
        <article class="my-3" id="disabled-forms">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Disabled forms</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/forms/overview/#disabled-forms"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <form>
                <fieldset disabled aria-label="Disabled fieldset example">
                  <div class="mb-3">
                    <label for="disabledTextInput" class="form-label"
                      >Disabled input</label
                    >
                    <input
                      type="text"
                      id="disabledTextInput"
                      class="form-control"
                      placeholder="Disabled input"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="disabledSelect" class="form-label"
                      >Disabled select menu</label
                    >
                    <select id="disabledSelect" class="form-select">
                      <option>Disabled select</option>
                    </select>
                  </div>
                  <div class="mb-3">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="disabledFieldsetCheck"
                        disabled
                      />
                      <label
                        class="form-check-label"
                        for="disabledFieldsetCheck"
                      >
                        Can't check this
                      </label>
                    </div>
                  </div>
                  <fieldset class="mb-3">
                    <legend>Disabled radios buttons</legend>
                    <div class="form-check">
                      <input
                        type="radio"
                        name="radios"
                        class="form-check-input"
                        id="disabledRadio1"
                        disabled
                      />
                      <label class="form-check-label" for="disabledRadio1"
                        >Disabled radio</label
                      >
                    </div>
                    <div class="mb-3 form-check">
                      <input
                        type="radio"
                        name="radios"
                        class="form-check-input"
                        id="disabledRadio2"
                        disabled
                      />
                      <label class="form-check-label" for="disabledRadio2"
                        >Another radio</label
                      >
                    </div>
                  </fieldset>
                  <div class="mb-3">
                    <label class="form-label" for="disabledCustomFile"
                      >Upload</label
                    >
                    <input
                      type="file"
                      class="form-control"
                      id="disabledCustomFile"
                      disabled
                    />
                  </div>
                  <div class="mb-3 form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="disabledSwitchCheckChecked"
                      checked
                      disabled
                    />
                    <label
                      class="form-check-label"
                      for="disabledSwitchCheckChecked"
                      >Disabled checked switch checkbox input</label
                    >
                  </div>
                  <div class="mb-3">
                    <label for="disabledRange" class="form-label"
                      >Disabled range</label
                    >
                    <input
                      type="range"
                      class="form-range"
                      min="0"
                      max="5"
                      step="0.5"
                      id="disabledRange"
                    />
                  </div>
                  <button type="submit" class="btn btn-primary">Submit</button>
                </fieldset>
              </form>
            </div>
          </div>
        </article>
        <article class="my-3" id="sizing">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Sizing</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/forms/form-control/#sizing"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <div class="mb-3">
                <input
                  class="form-control form-control-lg"
                  type="text"
                  placeholder=".form-control-lg"
                  aria-label=".form-control-lg example"
                />
              </div>
              <div class="mb-3">
                <select
                  class="form-select form-select-lg mb-3"
                  aria-label=".form-select-lg example"
                >
                  <option selected>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
              <div class="mb-3">
                <input
                  type="file"
                  class="form-control form-control-lg"
                  aria-label="Large file input example"
                />
              </div>
            </div>

            <div class="bd-example">
              <div class="mb-3">
                <input
                  class="form-control form-control-sm"
                  type="text"
                  placeholder=".form-control-sm"
                  aria-label=".form-control-sm example"
                />
              </div>
              <div class="mb-3">
                <select
                  class="form-select form-select-sm"
                  aria-label=".form-select-sm example"
                >
                  <option selected>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
              <div class="mb-3">
                <input
                  type="file"
                  class="form-control form-control-sm"
                  aria-label="Small file input example"
                />
              </div>
            </div>
          </div>
        </article>
        <article class="my-3" id="input-group">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Input group</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/forms/input-group/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">@</span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Username"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Recipient's username"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
                <span class="input-group-text" id="basic-addon2"
                  >@example.com</span
                >
              </div>
              <label for="basic-url" class="form-label">Your vanity URL</label>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon3"
                  >https://example.com/users/</span
                >
                <input
                  type="text"
                  class="form-control"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                />
              </div>
              <div class="input-group mb-3">
                <span class="input-group-text">$</span>
                <input
                  type="text"
                  class="form-control"
                  aria-label="Amount (to the nearest dollar)"
                />
                <span class="input-group-text">.00</span>
              </div>
              <div class="input-group">
                <span class="input-group-text">With textarea</span>
                <textarea
                  class="form-control"
                  aria-label="With textarea"
                ></textarea>
              </div>
            </div>
          </div>
        </article>
        <article class="my-3" id="floating-labels">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Floating labels</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/forms/floating-labels/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <form>
                <div class="form-floating mb-3">
                  <input
                    type="email"
                    class="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                  />
                  <label for="floatingInput">Email address</label>
                </div>
                <div class="form-floating">
                  <input
                    type="password"
                    class="form-control"
                    id="floatingPassword"
                    placeholder="Password"
                  />
                  <label for="floatingPassword">Password</label>
                </div>
              </form>
            </div>
          </div>
        </article>
        <article class="my-3" id="validation">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Validation</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/forms/validation/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <form class="row g-3">
                <div class="col-md-4">
                  <label for="validationServer01" class="form-label"
                    >First name</label
                  >
                  <input
                    type="text"
                    class="form-control is-valid"
                    id="validationServer01"
                    value="Mark"
                    required
                  />
                  <div class="valid-feedback">
                    Looks good!
                  </div>
                </div>
                <div class="col-md-4">
                  <label for="validationServer02" class="form-label"
                    >Last name</label
                  >
                  <input
                    type="text"
                    class="form-control is-valid"
                    id="validationServer02"
                    value="Otto"
                    required
                  />
                  <div class="valid-feedback">
                    Looks good!
                  </div>
                </div>
                <div class="col-md-4">
                  <label for="validationServerUsername" class="form-label"
                    >Username</label
                  >
                  <div class="input-group has-validation">
                    <span class="input-group-text" id="inputGroupPrepend3"
                      >@</span
                    >
                    <input
                      type="text"
                      class="form-control is-invalid"
                      id="validationServerUsername"
                      aria-describedby="inputGroupPrepend3"
                      required
                    />
                    <div class="invalid-feedback">
                      Please choose a username.
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="validationServer03" class="form-label"
                    >City</label
                  >
                  <input
                    type="text"
                    class="form-control is-invalid"
                    id="validationServer03"
                    required
                  />
                  <div class="invalid-feedback">
                    Please provide a valid city.
                  </div>
                </div>
                <div class="col-md-3">
                  <label for="validationServer04" class="form-label"
                    >State</label
                  >
                  <select
                    class="form-select is-invalid"
                    id="validationServer04"
                    required
                  >
                    <option selected disabled value="">Choose...</option>
                    <option>...</option>
                  </select>
                  <div class="invalid-feedback">
                    Please select a valid state.
                  </div>
                </div>
                <div class="col-md-3">
                  <label for="validationServer05" class="form-label">Zip</label>
                  <input
                    type="text"
                    class="form-control is-invalid"
                    id="validationServer05"
                    required
                  />
                  <div class="invalid-feedback">
                    Please provide a valid zip.
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-check">
                    <input
                      class="form-check-input is-invalid"
                      type="checkbox"
                      value=""
                      id="invalidCheck3"
                      required
                    />
                    <label class="form-check-label" for="invalidCheck3">
                      Agree to terms and conditions
                    </label>
                    <div class="invalid-feedback">
                      You must agree before submitting.
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <button class="btn btn-primary" type="submit">
                    Submit form
                  </button>
                </div>
              </form>
            </div>
          </div>
        </article>
      </section>

      <section id="components">
        <h2 class="sticky-xl-top fw-bold pt-3 pt-xl-5 pb-2 pb-xl-3">
          Components
        </h2>

        <article class="my-3" id="accordion">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Accordion</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/components/accordion/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h4 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Accordion Item #1
                    </button>
                  </h4>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <strong>This is the first item's accordion body.</strong>
                      It is hidden by default, until the collapse plugin adds
                      the appropriate classes that we use to style each element.
                      These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify
                      any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML
                      can go within the <code>.accordion-body</code>, though the
                      transition does limit overflow.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h4 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Accordion Item #2
                    </button>
                  </h4>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <strong>This is the second item's accordion body.</strong>
                      It is hidden by default, until the collapse plugin adds
                      the appropriate classes that we use to style each element.
                      These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify
                      any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML
                      can go within the <code>.accordion-body</code>, though the
                      transition does limit overflow.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h4 class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Accordion Item #3
                    </button>
                  </h4>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <strong>This is the third item's accordion body.</strong>
                      It is hidden by default, until the collapse plugin adds
                      the appropriate classes that we use to style each element.
                      These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify
                      any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML
                      can go within the <code>.accordion-body</code>, though the
                      transition does limit overflow.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
        <article class="my-3" id="alerts">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Alerts</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/components/alerts/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <div
                class="alert alert-primary alert-dismissible fade show"
                role="alert"
              >
                A simple primary alert with
                <a href="#" class="alert-link">an example link</a>. Give it a
                click if you like.
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
              <div
                class="alert alert-secondary alert-dismissible fade show"
                role="alert"
              >
                A simple secondary alert with
                <a href="#" class="alert-link">an example link</a>. Give it a
                click if you like.
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
              <div
                class="alert alert-success alert-dismissible fade show"
                role="alert"
              >
                A simple success alert with
                <a href="#" class="alert-link">an example link</a>. Give it a
                click if you like.
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
              <div
                class="alert alert-danger alert-dismissible fade show"
                role="alert"
              >
                A simple danger alert with
                <a href="#" class="alert-link">an example link</a>. Give it a
                click if you like.
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
              <div
                class="alert alert-warning alert-dismissible fade show"
                role="alert"
              >
                A simple warning alert with
                <a href="#" class="alert-link">an example link</a>. Give it a
                click if you like.
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
              <div
                class="alert alert-info alert-dismissible fade show"
                role="alert"
              >
                A simple info alert with
                <a href="#" class="alert-link">an example link</a>. Give it a
                click if you like.
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
              <div
                class="alert alert-light alert-dismissible fade show"
                role="alert"
              >
                A simple light alert with
                <a href="#" class="alert-link">an example link</a>. Give it a
                click if you like.
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
              <div
                class="alert alert-dark alert-dismissible fade show"
                role="alert"
              >
                A simple dark alert with
                <a href="#" class="alert-link">an example link</a>. Give it a
                click if you like.
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            </div>

            <div class="bd-example">
              <div class="alert alert-success" role="alert">
                <h4 class="alert-heading">Well done!</h4>
                <p>
                  Aww yeah, you successfully read this important alert message.
                  This example text is going to run a bit longer so that you can
                  see how spacing within an alert works with this kind of
                  content.
                </p>
                <hr />
                <p class="mb-0">
                  Whenever you need to, be sure to use margin utilities to keep
                  things nice and tidy.
                </p>
              </div>
            </div>
          </div>
        </article>
        <article class="my-3" id="badge">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Badge</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/components/badge/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <p class="h1">
                Example heading <span class="badge bg-primary">New</span>
              </p>
              <p class="h2">
                Example heading <span class="badge bg-secondary">New</span>
              </p>
              <p class="h3">
                Example heading <span class="badge bg-success">New</span>
              </p>
              <p class="h4">
                Example heading <span class="badge bg-danger">New</span>
              </p>
              <p class="h5">
                Example heading
                <span class="badge bg-warning text-dark">New</span>
              </p>
              <p class="h6">
                Example heading <span class="badge bg-info text-dark">New</span>
              </p>
              <p class="h6">
                Example heading
                <span class="badge bg-light text-dark">New</span>
              </p>
              <p class="h6">
                Example heading <span class="badge bg-dark">New</span>
              </p>
            </div>

            <div class="bd-example">
              <span class="badge rounded-pill bg-primary">Primary</span>
              <span class="badge rounded-pill bg-secondary">Secondary</span>
              <span class="badge rounded-pill bg-success">Success</span>
              <span class="badge rounded-pill bg-danger">Danger</span>
              <span class="badge rounded-pill bg-warning text-dark"
                >Warning</span
              >
              <span class="badge rounded-pill bg-info text-dark">Info</span>
              <span class="badge rounded-pill bg-light text-dark">Light</span>
              <span class="badge rounded-pill bg-dark">Dark</span>
            </div>
          </div>
        </article>
        <article class="my-3" id="breadcrumb">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Breadcrumb</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/components/breadcrumb/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="#">Home</a></li>
                  <li class="breadcrumb-item"><a href="#">Library</a></li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Data
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </article>
        <article class="my-3" id="buttons">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Buttons</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/components/buttons/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <button type="button" class="btn btn-primary">Primary</button>
              <button type="button" class="btn btn-secondary">Secondary</button>
              <button type="button" class="btn btn-success">Success</button>
              <button type="button" class="btn btn-danger">Danger</button>
              <button type="button" class="btn btn-warning">Warning</button>
              <button type="button" class="btn btn-info">Info</button>
              <button type="button" class="btn btn-light">Light</button>
              <button type="button" class="btn btn-dark">Dark</button>

              <button type="button" class="btn btn-link">Link</button>
            </div>

            <div class="bd-example">
              <button type="button" class="btn btn-outline-primary">
                Primary
              </button>
              <button type="button" class="btn btn-outline-secondary">
                Secondary
              </button>
              <button type="button" class="btn btn-outline-success">
                Success
              </button>
              <button type="button" class="btn btn-outline-danger">
                Danger
              </button>
              <button type="button" class="btn btn-outline-warning">
                Warning
              </button>
              <button type="button" class="btn btn-outline-info">Info</button>
              <button type="button" class="btn btn-outline-light">Light</button>
              <button type="button" class="btn btn-outline-dark">Dark</button>
            </div>

            <div class="bd-example">
              <button type="button" class="btn btn-primary btn-sm">
                Small button
              </button>
              <button type="button" class="btn btn-primary">
                Standard button
              </button>
              <button type="button" class="btn btn-primary btn-lg">
                Large button
              </button>
            </div>
          </div>
        </article>
        <article class="my-3" id="button-group">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Button group</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/components/button-group/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <div
                class="btn-toolbar"
                role="toolbar"
                aria-label="Toolbar with button groups"
              >
                <div
                  class="btn-group me-2"
                  role="group"
                  aria-label="First group"
                >
                  <button type="button" class="btn btn-secondary">1</button>
                  <button type="button" class="btn btn-secondary">2</button>
                  <button type="button" class="btn btn-secondary">3</button>
                  <button type="button" class="btn btn-secondary">4</button>
                </div>
                <div
                  class="btn-group me-2"
                  role="group"
                  aria-label="Second group"
                >
                  <button type="button" class="btn btn-secondary">5</button>
                  <button type="button" class="btn btn-secondary">6</button>
                  <button type="button" class="btn btn-secondary">7</button>
                </div>
                <div class="btn-group" role="group" aria-label="Third group">
                  <button type="button" class="btn btn-secondary">8</button>
                </div>
              </div>
            </div>
          </div>
        </article>
        <article class="my-3" id="card">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Card</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/components/card/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <div class="row  row-cols-1 row-cols-md-2 g-4">
                <div class="col">
                  <div class="card">
                    <svg
                      class="bd-placeholder-img card-img-top"
                      width="100%"
                      height="180"
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      aria-label="Placeholder: Image cap"
                      preserveAspectRatio="xMidYMid slice"
                      focusable="false"
                    >
                      <title>Placeholder</title>
                      <rect width="100%" height="100%" fill="#868e96" />
                      <text x="50%" y="50%" fill="#dee2e6" dy=".3em">
                        Image cap
                      </text>
                    </svg>

                    <div class="card-body">
                      <h5 class="card-title">Card title</h5>
                      <p class="card-text">
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </p>
                      <a href="#" class="btn btn-primary">Go somewhere</a>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-header">
                      Featured
                    </div>
                    <div class="card-body">
                      <h5 class="card-title">Card title</h5>
                      <p class="card-text">
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </p>
                      <a href="#" class="btn btn-primary">Go somewhere</a>
                    </div>
                    <div class="card-footer text-muted">
                      2 days ago
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">Card title</h5>
                      <p class="card-text">
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </p>
                    </div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">An item</li>
                      <li class="list-group-item">A second item</li>
                      <li class="list-group-item">A third item</li>
                    </ul>
                    <div class="card-body">
                      <a href="#" class="card-link">Card link</a>
                      <a href="#" class="card-link">Another link</a>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card">
                    <div class="row g-0">
                      <div class="col-md-4">
                        <svg
                          class="bd-placeholder-img"
                          width="100%"
                          height="250"
                          xmlns="http://www.w3.org/2000/svg"
                          role="img"
                          aria-label="Placeholder: Image"
                          preserveAspectRatio="xMidYMid slice"
                          focusable="false"
                        >
                          <title>Placeholder</title>
                          <rect width="100%" height="100%" fill="#868e96" />
                          <text x="50%" y="50%" fill="#dee2e6" dy=".3em">
                            Image
                          </text>
                        </svg>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Card title</h5>
                          <p class="card-text">
                            This is a wider card with supporting text below as a
                            natural lead-in to additional content. This content
                            is a little bit longer.
                          </p>
                          <p class="card-text">
                            <small class="text-muted"
                              >Last updated 3 mins ago</small
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
        <article class="my-3" id="carousel">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Carousel</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/components/carousel/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <div
                id="carouselExampleCaptions"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <div class="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <svg
                      class="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
                      width="800"
                      height="400"
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      aria-label="Placeholder: First slide"
                      preserveAspectRatio="xMidYMid slice"
                      focusable="false"
                    >
                      <title>Placeholder</title>
                      <rect width="100%" height="100%" fill="#777" />
                      <text x="50%" y="50%" fill="#555" dy=".3em">
                        First slide
                      </text>
                    </svg>

                    <div class="carousel-caption d-none d-md-block">
                      <h5>First slide label</h5>
                      <p>
                        Some representative placeholder content for the first
                        slide.
                      </p>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <svg
                      class="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
                      width="800"
                      height="400"
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      aria-label="Placeholder: Second slide"
                      preserveAspectRatio="xMidYMid slice"
                      focusable="false"
                    >
                      <title>Placeholder</title>
                      <rect width="100%" height="100%" fill="#666" />
                      <text x="50%" y="50%" fill="#444" dy=".3em">
                        Second slide
                      </text>
                    </svg>

                    <div class="carousel-caption d-none d-md-block">
                      <h5>Second slide label</h5>
                      <p>
                        Some representative placeholder content for the second
                        slide.
                      </p>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <svg
                      class="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
                      width="800"
                      height="400"
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      aria-label="Placeholder: Third slide"
                      preserveAspectRatio="xMidYMid slice"
                      focusable="false"
                    >
                      <title>Placeholder</title>
                      <rect width="100%" height="100%" fill="#555" />
                      <text x="50%" y="50%" fill="#333" dy=".3em">
                        Third slide
                      </text>
                    </svg>

                    <div class="carousel-caption d-none d-md-block">
                      <h5>Third slide label</h5>
                      <p>
                        Some representative placeholder content for the third
                        slide.
                      </p>
                    </div>
                  </div>
                </div>
                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </article>
        <article class="my-3" id="dropdowns">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Dropdowns</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/components/dropdowns/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <div
                class="btn-group w-100 align-items-center justify-content-between flex-wrap"
              >
                <div class="dropdown">
                  <button
                    class="btn btn-secondary btn-sm dropdown-toggle"
                    type="button"
                    id="dropdownMenuButtonSM"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Dropdown button
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButtonSM"
                  >
                    <li><h6 class="dropdown-header">Dropdown header</h6></li>
                    <li><a class="dropdown-item" href="#">Action</a></li>
                    <li>
                      <a class="dropdown-item" href="#">Another action</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">Something else here</a>
                    </li>
                    <li><hr class="dropdown-divider" /></li>
                    <li>
                      <a class="dropdown-item" href="#">Separated link</a>
                    </li>
                  </ul>
                </div>
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Dropdown button
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li><h6 class="dropdown-header">Dropdown header</h6></li>
                    <li><a class="dropdown-item" href="#">Action</a></li>
                    <li>
                      <a class="dropdown-item" href="#">Another action</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">Something else here</a>
                    </li>
                    <li><hr class="dropdown-divider" /></li>
                    <li>
                      <a class="dropdown-item" href="#">Separated link</a>
                    </li>
                  </ul>
                </div>
                <div class="dropdown">
                  <button
                    class="btn btn-secondary btn-lg dropdown-toggle"
                    type="button"
                    id="dropdownMenuButtonLG"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Dropdown button
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButtonLG"
                  >
                    <li><h6 class="dropdown-header">Dropdown header</h6></li>
                    <li><a class="dropdown-item" href="#">Action</a></li>
                    <li>
                      <a class="dropdown-item" href="#">Another action</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">Something else here</a>
                    </li>
                    <li><hr class="dropdown-divider" /></li>
                    <li>
                      <a class="dropdown-item" href="#">Separated link</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="bd-example">
              <div class="btn-group">
                <button type="button" class="btn btn-primary">Primary</button>
                <button
                  type="button"
                  class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span class="visually-hidden">Toggle Dropdown</span>
                </button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Another action</a></li>
                  <li>
                    <a class="dropdown-item" href="#">Something else here</a>
                  </li>
                </ul>
              </div>
              <!-- /btn-group -->
              <div class="btn-group">
                <button type="button" class="btn btn-secondary">
                  Secondary
                </button>
                <button
                  type="button"
                  class="btn btn-secondary dropdown-toggle dropdown-toggle-split"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span class="visually-hidden">Toggle Dropdown</span>
                </button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Another action</a></li>
                  <li>
                    <a class="dropdown-item" href="#">Something else here</a>
                  </li>
                </ul>
              </div>
              <!-- /btn-group -->
              <div class="btn-group">
                <button type="button" class="btn btn-success">Success</button>
                <button
                  type="button"
                  class="btn btn-success dropdown-toggle dropdown-toggle-split"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span class="visually-hidden">Toggle Dropdown</span>
                </button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Another action</a></li>
                  <li>
                    <a class="dropdown-item" href="#">Something else here</a>
                  </li>
                </ul>
              </div>
              <!-- /btn-group -->
              <div class="btn-group">
                <button type="button" class="btn btn-info">Info</button>
                <button
                  type="button"
                  class="btn btn-info dropdown-toggle dropdown-toggle-split"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span class="visually-hidden">Toggle Dropdown</span>
                </button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Another action</a></li>
                  <li>
                    <a class="dropdown-item" href="#">Something else here</a>
                  </li>
                </ul>
              </div>
              <!-- /btn-group -->
              <div class="btn-group">
                <button type="button" class="btn btn-warning">Warning</button>
                <button
                  type="button"
                  class="btn btn-warning dropdown-toggle dropdown-toggle-split"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span class="visually-hidden">Toggle Dropdown</span>
                </button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Another action</a></li>
                  <li>
                    <a class="dropdown-item" href="#">Something else here</a>
                  </li>
                </ul>
              </div>
              <!-- /btn-group -->
              <div class="btn-group">
                <button type="button" class="btn btn-danger">Danger</button>
                <button
                  type="button"
                  class="btn btn-danger dropdown-toggle dropdown-toggle-split"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span class="visually-hidden">Toggle Dropdown</span>
                </button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Another action</a></li>
                  <li>
                    <a class="dropdown-item" href="#">Something else here</a>
                  </li>
                </ul>
              </div>
              <!-- /btn-group -->
            </div>

            <div class="bd-example">
              <div
                class="btn-group w-100 align-items-center justify-content-between flex-wrap"
              >
                <div class="dropend">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropendMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Dropend button
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropendMenuButton">
                    <li><h6 class="dropdown-header">Dropdown header</h6></li>
                    <li><a class="dropdown-item" href="#">Action</a></li>
                    <li>
                      <a class="dropdown-item" href="#">Another action</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">Something else here</a>
                    </li>
                    <li><hr class="dropdown-divider" /></li>
                    <li>
                      <a class="dropdown-item" href="#">Separated link</a>
                    </li>
                  </ul>
                </div>
                <div class="dropup">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropupMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Dropup button
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropupMenuButton">
                    <li><h6 class="dropdown-header">Dropdown header</h6></li>
                    <li><a class="dropdown-item" href="#">Action</a></li>
                    <li>
                      <a class="dropdown-item" href="#">Another action</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">Something else here</a>
                    </li>
                    <li><hr class="dropdown-divider" /></li>
                    <li>
                      <a class="dropdown-item" href="#">Separated link</a>
                    </li>
                  </ul>
                </div>
                <div class="dropstart">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropstartMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Dropstart button
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropstartMenuButton"
                  >
                    <li><h6 class="dropdown-header">Dropdown header</h6></li>
                    <li><a class="dropdown-item" href="#">Action</a></li>
                    <li>
                      <a class="dropdown-item" href="#">Another action</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">Something else here</a>
                    </li>
                    <li><hr class="dropdown-divider" /></li>
                    <li>
                      <a class="dropdown-item" href="#">Separated link</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="bd-example">
              <div class="btn-group">
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownRightMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    End-aligned menu
                  </button>
                  <ul
                    class="dropdown-menu dropdown-menu-end"
                    aria-labelledby="dropdownRightMenuButton"
                  >
                    <li><h6 class="dropdown-header">Dropdown header</h6></li>
                    <li><a class="dropdown-item" href="#">Action</a></li>
                    <li>
                      <a class="dropdown-item" href="#">Another action</a>
                    </li>
                    <li><hr class="dropdown-divider" /></li>
                    <li>
                      <a class="dropdown-item" href="#">Separated link</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </article>
        <article class="my-3" id="list-group">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>List group</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/components/list-group/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <ul class="list-group">
                <li class="list-group-item disabled" aria-disabled="true">
                  A disabled item
                </li>
                <li class="list-group-item">A second item</li>
                <li class="list-group-item">A third item</li>
                <li class="list-group-item">A fourth item</li>
                <li class="list-group-item">And a fifth one</li>
              </ul>
            </div>

            <div class="bd-example">
              <ul class="list-group list-group-flush">
                <li class="list-group-item">An item</li>
                <li class="list-group-item">A second item</li>
                <li class="list-group-item">A third item</li>
                <li class="list-group-item">A fourth item</li>
                <li class="list-group-item">And a fifth one</li>
              </ul>
            </div>

            <div class="bd-example">
              <div class="list-group">
                <a href="#" class="list-group-item list-group-item-action"
                  >A simple default list group item</a
                >

                <a
                  href="#"
                  class="list-group-item list-group-item-action list-group-item-primary"
                  >A simple primary list group item</a
                >
                <a
                  href="#"
                  class="list-group-item list-group-item-action list-group-item-secondary"
                  >A simple secondary list group item</a
                >
                <a
                  href="#"
                  class="list-group-item list-group-item-action list-group-item-success"
                  >A simple success list group item</a
                >
                <a
                  href="#"
                  class="list-group-item list-group-item-action list-group-item-danger"
                  >A simple danger list group item</a
                >
                <a
                  href="#"
                  class="list-group-item list-group-item-action list-group-item-warning"
                  >A simple warning list group item</a
                >
                <a
                  href="#"
                  class="list-group-item list-group-item-action list-group-item-info"
                  >A simple info list group item</a
                >
                <a
                  href="#"
                  class="list-group-item list-group-item-action list-group-item-light"
                  >A simple light list group item</a
                >
                <a
                  href="#"
                  class="list-group-item list-group-item-action list-group-item-dark"
                  >A simple dark list group item</a
                >
              </div>
            </div>
          </div>
        </article>
        <article class="my-3" id="modal">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Modal</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/components/modal/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <div class="d-flex justify-content-between flex-wrap">
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalDefault"
                >
                  Launch demo modal
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdropLive"
                >
                  Launch static backdrop modal
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalCenteredScrollable"
                >
                  Vertically centered scrollable modal
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalFullscreen"
                >
                  Full screen
                </button>
              </div>
            </div>
          </div>
        </article>
        <article class="my-3" id="navs">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Navs</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/components/navs-tabs/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <nav class="nav">
                <a class="nav-link active" aria-current="page" href="#"
                  >Active</a
                >
                <a class="nav-link" href="#">Link</a>
                <a class="nav-link" href="#">Link</a>
                <a
                  class="nav-link disabled"
                  href="#"
                  tabindex="-1"
                  aria-disabled="true"
                  >Disabled</a
                >
              </nav>
            </div>

            <div class="bd-example">
              <nav>
                <div class="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                  <button
                    class="nav-link active"
                    id="nav-home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-home"
                    type="button"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                  >
                    Home
                  </button>
                  <button
                    class="nav-link"
                    id="nav-profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-profile"
                    type="button"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="false"
                  >
                    Profile
                  </button>
                  <button
                    class="nav-link"
                    id="nav-contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-contact"
                    type="button"
                    role="tab"
                    aria-controls="nav-contact"
                    aria-selected="false"
                  >
                    Contact
                  </button>
                </div>
              </nav>
              <div class="tab-content" id="nav-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <p>
                    <strong
                      >This is some placeholder content the Home tab's
                      associated content.</strong
                    >
                    Clicking another tab will toggle the visibility of this one
                    for the next. The tab JavaScript swaps classes to control
                    the content visibility and styling. You can use it with
                    tabs, pills, and any other <code>.nav</code>-powered
                    navigation.
                  </p>
                </div>
                <div
                  class="tab-pane fade"
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  <p>
                    <strong
                      >This is some placeholder content the Profile tab's
                      associated content.</strong
                    >
                    Clicking another tab will toggle the visibility of this one
                    for the next. The tab JavaScript swaps classes to control
                    the content visibility and styling. You can use it with
                    tabs, pills, and any other <code>.nav</code>-powered
                    navigation.
                  </p>
                </div>
                <div
                  class="tab-pane fade"
                  id="nav-contact"
                  role="tabpanel"
                  aria-labelledby="nav-contact-tab"
                >
                  <p>
                    <strong
                      >This is some placeholder content the Contact tab's
                      associated content.</strong
                    >
                    Clicking another tab will toggle the visibility of this one
                    for the next. The tab JavaScript swaps classes to control
                    the content visibility and styling. You can use it with
                    tabs, pills, and any other <code>.nav</code>-powered
                    navigation.
                  </p>
                </div>
              </div>
            </div>

            <div class="bd-example">
              <ul class="nav nav-pills">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#"
                    >Active</a
                  >
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">Link</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">Link</a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link disabled"
                    href="#"
                    tabindex="-1"
                    aria-disabled="true"
                    >Disabled</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </article>
        <article class="my-3" id="navbar">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Navbar</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/components/navbar/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <nav class="navbar navbar-expand-lg navbar-light bg-light">
                <div class="container-fluid">
                  <a class="navbar-brand" href="#">
                    <img
                      src="/docs/5.0/assets/brand/bootstrap-logo-white.svg"
                      width="38"
                      height="30"
                      class="d-inline-block align-top"
                      alt="Bootstrap"
                      loading="lazy"
                      style="filter: invert(1) grayscale(100%) brightness(200%);"
                    />
                  </a>
                  <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span class="navbar-toggler-icon"></span>
                  </button>
                  <div
                    class="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                      <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="#"
                          >Home</a
                        >
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#">Link</a>
                      </li>
                      <li class="nav-item dropdown">
                        <a
                          class="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Dropdown
                        </a>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <li><a class="dropdown-item" href="#">Action</a></li>
                          <li>
                            <a class="dropdown-item" href="#">Another action</a>
                          </li>
                          <li><hr class="dropdown-divider" /></li>
                          <li>
                            <a class="dropdown-item" href="#"
                              >Something else here</a
                            >
                          </li>
                        </ul>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link disabled"
                          href="#"
                          tabindex="-1"
                          aria-disabled="true"
                          >Disabled</a
                        >
                      </li>
                    </ul>
                    <form class="d-flex">
                      <input
                        class="form-control me-2"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                      />
                      <button class="btn btn-outline-dark" type="submit">
                        Search
                      </button>
                    </form>
                  </div>
                </div>
              </nav>

              <nav class="navbar navbar-expand-lg navbar-dark bg-primary mt-5">
                <div class="container-fluid">
                  <a class="navbar-brand" href="#">
                    <img
                      src="/docs/5.0/assets/brand/bootstrap-logo-white.svg"
                      width="38"
                      height="30"
                      class="d-inline-block align-top"
                      alt="Bootstrap"
                      loading="lazy"
                    />
                  </a>
                  <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent2"
                    aria-controls="navbarSupportedContent2"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span class="navbar-toggler-icon"></span>
                  </button>
                  <div
                    class="collapse navbar-collapse"
                    id="navbarSupportedContent2"
                  >
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                      <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="#"
                          >Home</a
                        >
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#">Link</a>
                      </li>
                      <li class="nav-item dropdown">
                        <a
                          class="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown2"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Dropdown
                        </a>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="navbarDropdown2"
                        >
                          <li><a class="dropdown-item" href="#">Action</a></li>
                          <li>
                            <a class="dropdown-item" href="#">Another action</a>
                          </li>
                          <li><hr class="dropdown-divider" /></li>
                          <li>
                            <a class="dropdown-item" href="#"
                              >Something else here</a
                            >
                          </li>
                        </ul>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link disabled"
                          href="#"
                          tabindex="-1"
                          aria-disabled="true"
                          >Disabled</a
                        >
                      </li>
                    </ul>
                    <form class="d-flex">
                      <input
                        class="form-control me-2"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                      />
                      <button class="btn btn-outline-light" type="submit">
                        Search
                      </button>
                    </form>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </article>
        <article class="my-3" id="pagination">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Pagination</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/components/pagination/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <nav aria-label="Pagination example">
                <ul class="pagination pagination-sm">
                  <li class="page-item"><a class="page-link" href="#">1</a></li>
                  <li class="page-item active" aria-current="page">
                    <a class="page-link" href="#">2</a>
                  </li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                </ul>
              </nav>
            </div>

            <div class="bd-example">
              <nav aria-label="Standard pagination example">
                <ul class="pagination">
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li class="page-item"><a class="page-link" href="#">1</a></li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>

            <div class="bd-example">
              <nav aria-label="Another pagination example">
                <ul class="pagination pagination-lg flex-wrap">
                  <li class="page-item disabled">
                    <a
                      class="page-link"
                      href="#"
                      tabindex="-1"
                      aria-disabled="true"
                      >Previous</a
                    >
                  </li>
                  <li class="page-item"><a class="page-link" href="#">1</a></li>
                  <li class="page-item active" aria-current="page">
                    <a class="page-link" href="#">2</a>
                  </li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item">
                    <a class="page-link" href="#">Next</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </article>
        <article class="my-3" id="popovers">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Popovers</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/components/popovers/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <button
                type="button"
                class="btn btn-lg btn-danger"
                data-bs-toggle="popover"
                title="Popover title"
                data-bs-content="And here's some amazing content. It's very engaging. Right?"
              >
                Click to toggle popover
              </button>
            </div>

            <div class="bd-example">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-container="body"
                data-bs-toggle="popover"
                data-bs-placement="top"
                data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
              >
                Popover on top
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-container="body"
                data-bs-toggle="popover"
                data-bs-placement="right"
                data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
              >
                Popover on end
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-container="body"
                data-bs-toggle="popover"
                data-bs-placement="bottom"
                data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
              >
                Popover on bottom
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-container="body"
                data-bs-toggle="popover"
                data-bs-placement="left"
                data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
              >
                Popover on start
              </button>
            </div>
          </div>
        </article>
        <article class="my-3" id="progress">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Progress</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/components/progress/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <div class="progress mb-3">
                <div
                  class="progress-bar"
                  role="progressbar"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  0%
                </div>
              </div>
              <div class="progress mb-3">
                <div
                  class="progress-bar bg-success w-25"
                  role="progressbar"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  25%
                </div>
              </div>
              <div class="progress mb-3">
                <div
                  class="progress-bar bg-info text-dark w-50"
                  role="progressbar"
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  50%
                </div>
              </div>
              <div class="progress mb-3">
                <div
                  class="progress-bar bg-warning text-dark w-75"
                  role="progressbar"
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  75%
                </div>
              </div>
              <div class="progress">
                <div
                  class="progress-bar bg-danger w-100"
                  role="progressbar"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  100%
                </div>
              </div>
            </div>

            <div class="bd-example">
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  style="width: 15%"
                  aria-valuenow="15"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
                <div
                  class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                  role="progressbar"
                  style="width: 40%"
                  aria-valuenow="40"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </article>
        <article class="my-3" id="scrollspy">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Scrollspy</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/components/scrollspy/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <nav
                id="navbar-example2"
                class="navbar navbar-light bg-light px-3"
              >
                <a class="navbar-brand" href="#">Navbar</a>
                <ul class="nav nav-pills">
                  <li class="nav-item">
                    <a class="nav-link active" href="#scrollspyHeading1"
                      >First</a
                    >
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#scrollspyHeading2">Second</a>
                  </li>
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link dropdown-toggle"
                      data-bs-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-expanded="false"
                      >Dropdown</a
                    >
                    <ul class="dropdown-menu">
                      <li>
                        <a class="dropdown-item" href="#scrollspyHeading3"
                          >Third</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#scrollspyHeading4"
                          >Fourth</a
                        >
                      </li>
                      <li><hr class="dropdown-divider" /></li>
                      <li>
                        <a class="dropdown-item" href="#scrollspyHeading5"
                          >Fifth</a
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div
                data-bs-spy="scroll"
                data-bs-target="#navbar-example2"
                data-bs-offset="0"
                class="scrollspy-example"
                tabindex="0"
              >
                <h4 id="scrollspyHeading1">First heading</h4>
                <p>
                  This is some placeholder content for the scrollspy page. Note
                  that as you scroll down the page, the appropriate navigation
                  link is highlighted. It's repeated throughout the component
                  example. We keep adding some more example copy here to
                  emphasize the scrolling and highlighting.
                </p>
                <h4 id="scrollspyHeading2">Second heading</h4>
                <p>
                  This is some placeholder content for the scrollspy page. Note
                  that as you scroll down the page, the appropriate navigation
                  link is highlighted. It's repeated throughout the component
                  example. We keep adding some more example copy here to
                  emphasize the scrolling and highlighting.
                </p>
                <h4 id="scrollspyHeading3">Third heading</h4>
                <p>
                  This is some placeholder content for the scrollspy page. Note
                  that as you scroll down the page, the appropriate navigation
                  link is highlighted. It's repeated throughout the component
                  example. We keep adding some more example copy here to
                  emphasize the scrolling and highlighting.
                </p>
                <h4 id="scrollspyHeading4">Fourth heading</h4>
                <p>
                  This is some placeholder content for the scrollspy page. Note
                  that as you scroll down the page, the appropriate navigation
                  link is highlighted. It's repeated throughout the component
                  example. We keep adding some more example copy here to
                  emphasize the scrolling and highlighting.
                </p>
                <h4 id="scrollspyHeading5">Fifth heading</h4>
                <p>
                  This is some placeholder content for the scrollspy page. Note
                  that as you scroll down the page, the appropriate navigation
                  link is highlighted. It's repeated throughout the component
                  example. We keep adding some more example copy here to
                  emphasize the scrolling and highlighting.
                </p>
              </div>
            </div>
          </div>
        </article>
        <article class="my-3" id="spinners">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Spinners</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/components/spinners/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-border text-secondary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-border text-success" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-border text-danger" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-border text-warning" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-border text-info" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-border text-light" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-border text-dark" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>

            <div class="bd-example">
              <div class="spinner-grow text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-secondary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-success" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-danger" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-warning" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-info" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-light" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-dark" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </article>
        <article class="my-3" id="toasts">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Toasts</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/components/toasts/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example bg-dark p-5 align-items-center">
              <div
                class="toast"
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
              >
                <div class="toast-header">
                  <svg
                    class="bd-placeholder-img rounded me-2"
                    width="20"
                    height="20"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    preserveAspectRatio="xMidYMid slice"
                    focusable="false"
                  >
                    <rect width="100%" height="100%" fill="#007aff" />
                  </svg>

                  <strong class="me-auto">Bootstrap</strong>
                  <small class="text-muted">11 mins ago</small>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="toast"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="toast-body">
                  Hello, world! This is a toast message.
                </div>
              </div>
            </div>
          </div>
        </article>
        <article class="mt-3 mb-5 pb-5" id="tooltips">
          <div
            class="bd-heading sticky-xl-top align-self-start mt-5 mb-3 mt-xl-0 mb-xl-2"
          >
            <h3>Tooltips</h3>
            <a
              class="d-flex align-items-center"
              href="https://getbootstrap.com/docs/5.0/components/tooltips/"
              >Documentation</a
            >
          </div>

          <div>
            <div class="bd-example tooltip-demo">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Tooltip on top"
              >
                Tooltip on top
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="Tooltip on end"
              >
                Tooltip on end
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title="Tooltip on bottom"
              >
                Tooltip on bottom
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-toggle="tooltip"
                data-bs-placement="left"
                title="Tooltip on start"
              >
                Tooltip on start
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-toggle="tooltip"
                data-bs-html="true"
                title="<em>Tooltip</em> <u>with</u> <b>HTML</b>"
              >
                Tooltip with HTML
              </button>
            </div>
          </div>
        </article>
      </section>
    </div>

    <div
      class="modal fade"
      id="exampleModalDefault"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            ...
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="staticBackdropLive"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLiveLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLiveLabel">
              Modal title
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>
              I will not close if you click outside me. Don't even try to press
              escape key.
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="button" class="btn btn-primary">Understood</button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="exampleModalCenteredScrollable"
      tabindex="-1"
      aria-labelledby="exampleModalCenteredScrollableTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenteredScrollableTitle">
              Modal title
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>
              This is some placeholder content to show the scrolling behavior
              for modals. We use repeated line breaks to demonstrate how content
              can exceed minimum inner height, thereby showing inner scrolling.
              When content becomes longer than the prefedined max-height of
              modal, content will be cropped and scrollable within the modal.
            </p>
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            <p>This content should appear at the bottom after you scroll.</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="exampleModalFullscreen"
      tabindex="-1"
      aria-labelledby="exampleModalFullscreenLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title h4" id="exampleModalFullscreenLabel">
              Full screen modal
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            ...
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheatSheet",
  route: {
    name: "CheatSheet",
    path: "/cheatsheet",
  },
  methods: {
    init() {
      /* global bootstrap: false */

      (function() {
        "use strict";

        // Tooltip and popover demos
        document.querySelectorAll(".tooltip-demo").forEach(function(tooltip) {
          new bootstrap.Tooltip(tooltip, {
            selector: '[data-bs-toggle="tooltip"]',
          });
        });

        document
          .querySelectorAll('[data-bs-toggle="popover"]')
          .forEach(function(popover) {
            new bootstrap.Popover(popover);
          });

        document.querySelectorAll(".toast").forEach(function(toastNode) {
          var toast = new bootstrap.Toast(toastNode, {
            autohide: false,
          });

          toast.show();
        });

        // Disable empty links and submit buttons
        document
          .querySelectorAll('[href="#"], [type="submit"]')
          .forEach(function(link) {
            link.addEventListener("click", function(event) {
              event.preventDefault();
            });
          });

        function setActiveItem() {
          var hash = window.location.hash;

          if (hash === "") {
            return;
          }

          var link = document.querySelector('.bd-aside a[href="' + hash + '"]');

          if (!link) {
            return;
          }

          var active = document.querySelector(".bd-aside .active");
          var parent = link.parentNode.parentNode.previousElementSibling;

          link.classList.add("active");

          if (parent.classList.contains("collapsed")) {
            parent.click();
          }

          if (!active) {
            return;
          }

          var expanded = active.parentNode.parentNode.previousElementSibling;

          active.classList.remove("active");

          if (expanded && parent !== expanded) {
            expanded.click();
          }
        }

        setActiveItem();
        window.addEventListener("hashchange", setActiveItem);
      })();
    },
    mounted() {
      this.$nextTick(() => this.init());
    },
  },
};
</script>

<style scoped>
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
/**
 * Bootstrap "Journal code" icon
 * @link https://icons.getbootstrap.com/icons/journal-code/
 */
.bd-heading a::before {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 0.25rem;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%25230d6efd' viewBox='0 0 16 16'%3E%3Cpath d='M4 1h8a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2h1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1H2a2 2 0 0 1 2-2z'/%3E%3Cpath d='M2 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H2zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H2zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H2z'/%3E%3Cpath fill-rule='evenodd' d='M8.646 5.646a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L10.293 8 8.646 6.354a.5.5 0 0 1 0-.708zm-1.292 0a.5.5 0 0 0-.708 0l-2 2a.5.5 0 0 0 0 .708l2 2a.5.5 0 0 0 .708-.708L5.707 8l1.647-1.646a.5.5 0 0 0 0-.708z'/%3E%3C/svg%3E");
  background-size: 1em;
}

/* stylelint-disable-next-line selector-max-universal */
.bd-heading + div > * + * {
  margin-top: 3rem;
}

/* Table of contents */
.bd-aside a {
  padding: 0.1875rem 0.5rem;
  margin-top: 0.125rem;
  margin-left: 0.3125rem;
  color: rgba(0, 0, 0, 0.65);
  text-decoration: none;
}

.bd-aside a:hover,
.bd-aside a:focus {
  color: rgba(0, 0, 0, 0.85);
  background-color: rgba(121, 82, 179, 0.1);
}

.bd-aside .active {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
}

.bd-aside .btn {
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
  border: 0;
}

.bd-aside .btn:hover,
.bd-aside .btn:focus {
  color: rgba(0, 0, 0, 0.85);
  background-color: rgba(121, 82, 179, 0.1);
}

.bd-aside .btn:focus {
  box-shadow: 0 0 0 1px rgba(121, 82, 179, 0.7);
}

.bd-aside .btn::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform 0.35s ease;

  /* rtl:raw:
  transform: rotate(180deg) translateX(-2px);
  */
  transform-origin: 0.5em 50%;
}

.bd-aside .btn[aria-expanded="true"]::before {
  transform: rotate(90deg) /* rtl:ignore */;
}

/* Examples */
.scrollspy-example {
  position: relative;
  height: 200px;
  margin-top: 0.5rem;
  overflow: auto;
}

[id="modal"] .bd-example .btn,
[id="buttons"] .bd-example .btn,
[id="tooltips"] .bd-example .btn,
[id="popovers"] .bd-example .btn,
[id="dropdowns"] .bd-example .btn-group,
[id="dropdowns"] .bd-example .dropdown,
[id="dropdowns"] .bd-example .dropup,
[id="dropdowns"] .bd-example .dropend,
[id="dropdowns"] .bd-example .dropstart {
  margin: 0 1rem 1rem 0;
}

/* Layout */
@media (min-width: 1200px) {
  .body {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 4fr 1fr;
    grid-template-rows: auto;
  }

  .bd-header {
    position: fixed;
    top: 0;
    /* rtl:begin:ignore */
    right: 0;
    left: 0;
    /* rtl:end:ignore */
    z-index: 1030;
    grid-column: 1 / span 3;
  }

  .bd-aside,
  .bd-cheatsheet {
    padding-top: 4rem;
  }

  /**
   * 1. Too bad only Firefox supports subgrids ATM
   */
  .bd-cheatsheet,
  .bd-cheatsheet section,
  .bd-cheatsheet article {
    display: inherit; /* 1 */
    gap: inherit; /* 1 */
    grid-template-columns: 1fr 4fr;
    grid-column: 1 / span 2;
    grid-template-rows: auto;
  }

  .bd-aside {
    grid-area: 1 / 3;
    scroll-margin-top: 4rem;
  }

  .bd-cheatsheet section,
  .bd-cheatsheet section > h2 {
    top: 2rem;
    scroll-margin-top: 2rem;
  }

  .bd-cheatsheet section > h2::before {
    position: absolute;
    /* rtl:begin:ignore */
    top: 0;
    right: 0;
    bottom: -2rem;
    left: 0;
    /* rtl:end:ignore */
    z-index: -1;
    content: "";
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) calc(100% - 3rem),
      rgba(255, 255, 255, 0.01)
    );
  }

  .bd-cheatsheet article,
  .bd-cheatsheet .bd-heading {
    top: 8rem;
    scroll-margin-top: 8rem;
  }

  .bd-cheatsheet .bd-heading {
    z-index: 1;
  }
}
</style>
