import Vue from 'vue'
import VueRouter from 'vue-router'
import { Views } from '@/views' // imports all Views from Views folder
import Home from "@/views/Home";
import notFound from "@/views/NotFound";

Vue.use(VueRouter)


let routes = [
    {
        path: '/',
        name: 'AtomicX Home Page',
        component: Home
    },

]
routes = routes.concat(Views)
routes.push({ path: "*", name: "404", component: notFound });

console.log("Available Views", Views);
console.log('Available Routes', routes)
/* eslint-disable */
const router = new VueRouter({
    base: process.env.NODE_ENV === 'production'
        ? '/' // config.production.url
        : '/',
    mode: 'history',
    routes,
    // scrollBehavior: (to, from, savedPosition) => {
    //     return { x: 0, y: 0 }
    // }
})

export default router