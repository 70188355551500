<template>
  <div>
    <main class="mx-auto">

      <!-- Jumbotron -->
      <section class="container-xxl position-relative jumbotron bg-shape my-auto px-4 px-5 px-md-6 px-xl-7 light-blue" style="">
        <div class="col-lg-9 position-relative">
          
          <h1 class="display-4 mb-8 position-relative">Atomic X provides <span class="d-block d-md-inline primary-color">centralized control</span> over every aspect of your <span class="d-block d-md-inline primary-color">digital offering</span></h1>
          <p class="h5 fw-normal position-relative">Based in Ottawa and Toronto, with a highly skilled team of AI Strategists, Computer Engineers, Data Scientists and UX Specialists, Atomic X was designed to be a one-of-a-kind platform that provides future proof digital management for every aspect of your organization.</p>
        </div>

      </section>
      <!-- Jumbotron -->

      <!-- Section - Profile -->
      <section
        class="section-two-col pt-0 pb-3 px-md-4 mx-lg-auto mt-lg-7"
      >
        <div class="row flex-column-reverse flex-lg-row-reverse">
          <div
            class="d-flex flex-column align-content-start align-items-start justify-content-lg-center col-sm-12 col-lg-7 col-xl-8 p-6 p-lg-6 p-xxl-7 pt-xxl-0"
            data-aos="zoom-in-left"
          >
            <p>
              Dan has been developing online initiatives since 1999. As an entrepreneur and Internet strategist, Dan has pioneered a variety of Internet applications and methodologies that have been utilized by hundreds of organizations across the globe. For the past 15 years, Dan has been the CEO of Atomic Motion where he’s fostered the company’s growth into a leading-edge new media company with a client list that includes some of the largest and most respected organizations in North America.
            </p>

            <p class="mt-2">Prior to Atomic Motion, Dan started his first Internet company which he took public on the TSXV in 2000. As CEO, Dan spent two years growing the company which specialized in the development of Online Communities. Prior to this, Dan spent two years working with Southam (Canada’s largest newspaper publisher) where he helped launch their local portal initiatives.</p>

            <p class="mt-2 position-relative">Dan is a graduate from the University of Ottawa with a degree in Communications.</p>

          </div>

          <div class="col-sm-12 col-lg-4 bg-img p-0 flex-column align-content-start align-items-center justify-content-center">
            <div
              class="img-profile"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/dan-cummins.png') + ')',
              }"
              data-aos="zoom-in-up"
            >
            </div>
            <h2 class="primary-color fw-normal">Dan Cummins</h2>
            <h3 class="h4 fw-normal">Chief Executive Officer</h3>
          </div>
        </div>
      </section>
      <!-- Section - Profile -->

      <!-- Section - Profile  -->
      <section
        class="section-two-col pt-0 pb-3 px-md-4 mb-lg-0 mt-lg-7 mx-lg-auto"
      >
        <div class="row flex-column-reverse flex-lg-row">
          <div
            class="d-flex flex-column align-content-start align-items-start justify-content-lg-center col-sm-12 col-lg-7 col-xl-8 p-6 p-lg-6 p-xxl-7 pt-xxl-0"
            data-aos="zoom-in-left"
          >
            <p>
              As a highly motivated and innovative serial entrepreneur with over 20 years of management experience, Sonny Juane is best known for his drive, passion and ability to redefine and change business models to adapt to the ever changing marketplace. 
            </p>

            <p class="mt-2">With a keen eye for scalable processes and implementing change management systems - Sonny has redefined and innovated leading edge app development frameworks that are now being utilized by large and small organizations across the continent.</p>

            <p class="mt-2">Prior to Atomic X, Sonny was the founder and CEO of ARC Labs (acquired by Atomic Moton in 2020) which focused on the development and integration of progressive web apps and web-based tools for enterprise and SMB market places.</p>

            <p class="mt-2">Sonny studied Biology at the University of Ottawa before moving on to pursue his passion for technology and entrepreneurialism.</p>

          </div>

          <div class="col-sm-12 col-lg-4 bg-img p-0 flex-column align-content-start align-items-center justify-content-center">
            <div
              class="img-profile"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/sonny-juane.png') + ')',
              }"
              data-aos="zoom-in-up"
            >
            </div>
            <h2 class="primary-color fw-normal">Sonny Juane</h2>
            <h3 class="h4 fw-normal">Chief Technology Officer</h3>
          </div>
        </div>
      </section>
      <!-- Section - Profile -->

     <!-- Section - Profile -->
      <section
        class="section-two-col pt-0 pb-3 px-md-4 mb-lg-0 mt-lg-7 mx-lg-auto"
      >
        <div class="row flex-column-reverse flex-lg-row-reverse justify-content-lg-center">
          <div
            class="d-flex flex-column align-content-start align-items-start justify-content-lg-center col-sm-12 col-lg-7 col-xl-8 p-6 p-lg-6 p-xxl-7 pt-xxl-0"
            data-aos="zoom-in-left"
          >
            <p class="mt-2">As an expert in conversion psychology and persuasive architecture, Kyle has been pioneering the application of machine learning in a practical setting for more than 5 years. With 10 years of digital experience under his belt as CEO of WebMarketers, he has led the charge resulting in a 70% year-over-year growth since inception.</p>

            <p class="mt-2">As a career entrepreneur, Kyle has founded 7 companies and is also a founder and board member of Athletes Combating Racism (athletescombatingracism.org), an organization that provides mentorship, tutoring, and access to technology for underprivileged youth.</p>

            <p class="mt-2">Kyle is a graduate of Carleton University with a degree in Aerospace Engineering. He’s also an alumni of the Carleton basketball team where he won 4 national championships.</p>

          </div>

          <div class="col-sm-12 col-lg-4 bg-img p-0 flex-column align-content-start align-items-center justify-content-center">
            <div
              class="img-profile"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/kyle-smendziuk.png') + ')',
              }"
              data-aos="zoom-in-up"
            >
            </div>
            <h2 class="primary-color fw-normal">Kyle Smendziuk</h2>
            <h3 class="h4 fw-normal">Chief Operating Officer</h3>
          </div>
        </div>
      </section>
      <!-- Section - Profile -->

      <!-- Section - Profile  -->
      <section
        class="section-two-col pt-0 pb-3 px-md-4 mb-lg-0 mt-lg-7 mx-lg-auto"
      >
        <div class="row flex-column-reverse flex-lg-row">
          <div
            class="d-flex flex-column align-content-start align-items-start justify-content-lg-center col-sm-12 col-lg-7 col-xl-8 p-6 p-lg-6 p-xxl-7 pt-xxl-0"
            data-aos="zoom-in-left"
          >
            <p>
              Eric has been working in the field of Machine Learning and Artificial Intelligence since 2015. With a passion for training neural networks, Eric’s core responsibility as Director, Data Science of Atomic X is to oversee the development of its core product – Atomic CHAT<sup>TM</sup>.
            </p>

            <p class="mt-2">Prior to Atomic X, Eric worked for a New York based consulting firm where he managed complex projects around the world, and launched multiple initiatives in workflow automation. Eric played a pivotal role in organizing and executing world-wide training rollouts for companies such as SAP, Microsoft and Autodesk, to name a few.</p>

            <p class="mt-2">Eric graduated from the University of British Columbia with a degree in Engineering Physics, and was a recipient of multiple scholarships and awards throughout his academic career.</p>

          </div>

          <div class="col-sm-12 col-lg-4 bg-img p-0 flex-column align-content-start align-items-center justify-content-center">
            <div
              class="img-profile"
              :style="{
               backgroundImage:
                  'url(' + require('@/assets/eric-moller.png') + ')',
              }"
              data-aos="zoom-in-up"
            >
            </div>
            <h2 class="primary-color fw-normal">Eric Moller</h2>
            <h3 class="h4 fw-normal">Director, Data Science</h3>
          </div>
        </div>
      </section>
      <!-- Section - Profile -->

      <!-- Section - Profile -->
      <section
        class="section-two-col pt-0 pb-3 px-md-4 mb-lg-0 mt-lg-7 mx-lg-auto"
      >
        <div class="row flex-column-reverse flex-lg-row-reverse justify-content-lg-center">
          <div
            class="d-flex flex-column align-content-start align-items-start justify-content-lg-center col-sm-12 col-lg-7 col-xl-8 p-6 p-lg-6 p-xxl-7 pt-xxl-0"
            data-aos="zoom-in-left"
          >
             <p>
              As CFO, Mike brings over 10 years of financial insight working within the technology field. Prior to Atomic X, Mike was the Comptroller for Shopify for 7 years. While working at Shopify, Mike helped lead them through 2 private financing rounds for $15 Million and $100 Million respectively, as well as their wildly successful IPO in 2015. During his time at Shopify, Mike gained a tremendous amount of knowledge and insight into the inner-workings of early and late-stage venture capital financing.
            </p>

            <p class="mt-2">Prior to Shopify, Mike was the Accounting Manager for Protus where he helped them grow through their $213 million acquisition.</p>

            <p class="mt-2">Mike is a graduate of Western University with a degree in Administrative and Commercial Studies.</p>

          </div>

          <div class="col-sm-12 col-lg-4 bg-img p-0 flex-column align-content-start align-items-center justify-content-center">
            <div
              class="img-profile"
              :style="{
                 backgroundImage:
                'url(' + require('@/assets/mike-gagnon.png') + ')',
              }"
              data-aos="zoom-in-up"
            >
            </div>
            <h2 class="primary-color fw-normal">Mike Gagnon</h2>
            <h3 class="h4 fw-normal">Chief Financial Officer</h3>
          </div>
        </div>
      </section>
      <!-- Section - Profile -->

      <!-- Section - Profile  -->
      <section
        class="section-two-col pt-0 pb-3 px-md-4 mb-lg-0 mt-lg-7 mx-lg-auto"
      >
        <div class="row flex-column-reverse flex-lg-row">
          <div
            class="d-flex flex-column align-content-start align-items-start justify-content-lg-center col-sm-12 col-lg-7 col-xl-8 p-6 p-lg-6 p-xxl-7 pt-xxl-0"
            data-aos="zoom-in-left"
          >
            <p>
              Alexandre has been coding since 2011. He quickly grew into the lead developer role at Atomic Motion before moving to Atomic X. Now he’s using his knowledge and expertise in full-stack development to quickly build out Atomic X’s platform and products.
            </p>

            <p class="mt-2">Prior to Atomic X, Alexandre helped Atomic Motion bring some of the most cutting edge designs and experiences to life. With a passion for the web, he’s always looking to tackle new challengers and help make the web a better place for everyone.</p>

            <p class="mt-2">Alexandre studied Political Science at the University of Ottawa, before moving to Computer Engineering.</p>

          </div>

          <div class="col-sm-12 col-lg-4 bg-img p-0 flex-column align-content-start align-items-center justify-content-center">
            <div
              class="img-profile"
              :style="{
              backgroundImage:
                  'url(' + require('@/assets/alexandre-grenier.png') + ')',
              }"
              data-aos="zoom-in-up"
            >
            </div>
            <h2 class="primary-color fw-normal">Alex Grenier</h2>
            <h3 class="h4 fw-normal">Chief Engineer</h3>
          </div>
        </div>
      </section>
      <!-- Section - Profile -->

      <!-- Section - Profile -->
      <!-- <section
        class="section-two-col pt-0 pb-3 px-md-4 mb-lg-0 mt-lg-7 mx-lg-auto"
      >
        <div class="row flex-column-reverse flex-lg-row-reverse justify-content-lg-center">
          <div
            class="d-flex flex-column align-content-start align-items-start justify-content-lg-center col-sm-12 col-lg-7 col-xl-8 p-6 p-lg-6 p-xxl-7 pt-xxl-0"
            data-aos="zoom-in-left"
          >
            <p>
              Goran brings his intimate knowledge of user experience and design to guide the UI division of Atomic X. As the lead design and UI architect for Rufus, Goran is helping to usher in a new paradigm for chatbots and online consumer interactions.
            </p>

            <p class="mt-2">As one of the leading designers in Canada, Goran has been recognized with multiple awards from Canadian and International organizations, including the internationally recognized Norman Neilson award for best UI. Goran has also participated in corporate re-branding strategies for a variety of multi-national organizations.</p>

            <p class="mt-2">Goran is a top graduate from Algonquin College’s Graphic Design Program in Ottawa.</p>

          </div>

          <div class="col-sm-12 col-lg-4 bg-img p-0 flex-column align-content-start align-items-center justify-content-center">
            <div
              class="img-profile"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/goran-mraovic.png') + ')',
              }"
              data-aos="zoom-in-up"
            >
            </div>
            <h2 class="primary-color fw-normal">Goran Mraovic</h2>
            <h3 class="h4 fw-normal">Director of UX Design</h3>
          </div>
        </div>
      </section> -->
      <!-- Section - Profile -->

      <div class="d-none d-lg-block mb-7" style="height: 200px;"></div>

     
    </main>
  </div>
</template>

<script>
export default {
  name: "about",
  route: {
  path: "/about",
   name: "AtomicX About",
  },
  head: {
    title: {
      inner: "Atomic X | Toronto Artificial Intelligence Agency",
    },
    // Meta tags
    meta: [
      {
        name: "description",
        content:
          "AtomicX is Toronto&#039;s cutting edge artificial intelligence agency. A team of strategists, designers, and builders redefining today’s online marketplace.",
        id: "desc",
      },
    ],
    link: [
      {
        rel: "icon",
        href: require("../assets/favicon.png"),
        sizes: "32x132",
        type: "image/png",
      },
    ],
  },
};
</script>
<style scoped>
.mb-8{
  margin-bottom: 9.5rem !important;
}
</style>
