import Vue from "vue";
// loads ALL components in ./ folder
const ComponentContext = require.context("./", true, /\.vue$/i, "lazy");

export default ComponentContext.keys().forEach(componentFilePath => {
    const componentName = componentFilePath.split("/").pop().split(".")[0];
    Vue.component(componentName, () => ComponentContext(componentFilePath));
    //console.log('Global Components', componentName)
});

