<template>
  <div>
    <main class="mx-auto">
      <!-- Page Navigation -->
      <section
        class="container-xxl page-navigation my-auto mt-7 mb-2 p-5 p-lg-6 mb-lg-6 px-xxl-7"
        data-aos="zoom-in-right"
      >
        <div
          class="row d-flex flex-row align-center align-items-start justify-content-center justify-content-md-start col-xl-9"
        >
          <h1
            class="display-6 mb-4 font-prompt mt-3 primary-color text-center text-md-start"
          >
            Our Products
          </h1>

          <a class="btn-page-anchor-link" href="#section-atomic-cms">
            <span class="icon">
              <img
                class="icon"
                src="@/assets/icon-atomic-cms.png"
                alt="Icon - Atomic CMS"
              />
            </span>
            <span>Atomic <span class="primary-color">CMS</span></span>
          </a>

          <a class="btn-page-anchor-link" href="#section-atomic-chat">
             <span class="icon">
              <img
                class="icon"
                src="@/assets/icon-atomic-chat.png"
                alt="Icon - Atomic Chat"
              />
            </span>
            <span>Atomic <span class="primary-color">CHAT</span></span>
          </a>

          <a class="btn-page-anchor-link" href="#section-atomic-app">
             <span class="icon">
              <img
                class="icon"
                src="@/assets/icon-atomic-app.png"
                alt="Icon - Atomic APP"
              />
            </span>
            <span>Atomic <span class="primary-color">APP</span></span>
          </a>

          <a class="btn-page-anchor-link" href="#section-atomic-data">
             <span class="icon">
              <img
                class="icon"
                src="@/assets/icon-atomic-data.png"
                alt="Icon - Atomic DATA"
              />
            </span>
            <span>Atomic <span class="primary-color">DATA</span></span>
          </a>
        </div>
      </section>
      <!-- Page Navigation -->

      <!-- Section - Atomic CMS  -->
      <section
        class="section-two-col pt-6 pb-3 pt-lg-0 mb-lg-0 mx-lg-auto section-atomic-app"
        id="section-atomic-cms"
      >
        <div class="row flex-column-reverse flex-md-row">
          <div
            class="d-flex flex-column align-content-start align-items-start justify-content-lg-center col-sm-12 col-md-6 p-5 p-lg-6 p-xxl-7 pt-xxl-0"
            data-aos="zoom-in-left"
          >
            <h2 class="h6 font-prompt primary-color">A TRUE HEADLESS CMS</h2>
            <h3 class="display-6 mb-4 font-prompt mt-3">
              Atomic <span class="primary-color">CMS</span>
            </h3>
            <p class="mt-1 w-90">
              Atomic CMS is an enterprise-grade digital platform that gives you
              centralized control of your contextual experiences across any
              digital touchpoint in your company’s ecosystem: website, native
              app, digital kiosk, touchscreen or other digital touch points.
            </p>
          </div>

          <div
            class="col-sm-12 col-md-6 bg-img bg-height p-0"
            :style="{
              backgroundImage:
                'url(' + require('@/assets/img-atomic-cms-full.png') + ')',
            }"
          >
            <img
              class="icon icon-overflow"
              src="@/assets/icon-edit.png"
              alt="Icon - app edit"
              data-aos="zoom-in-left"
              data-aos-duration="1000"
            />
          </div>
        </div>
      </section>
      <!-- Section - Atomic CMS -->

      <!-- Section 3 Columns -->
      <section
        class="container-xxl my-auto mb-2 pb-5 pt-md-7 px-5 p-lg-6 mb-lg-4 pt-lg-7 px-xxl-6"
        data-aos="zoom-in-top"
      >
        <div
          class="row d-flex flex-row align-center align-items-start justify-content-center justify-content-md-between"
        >
          <div
            class="col-12 col-lg-4 mt-0 p-0"
            data-aos="zoom-in-right"
          >
            <h2 class="h5 mb-5 fw-bold">Flexibility for Marketers</h2>
            <p class="mt-2 w-90">
              Built for marketers who can create and personalize content for any
              endpoint.
            </p>
            <p class="mt-2 w-90">
              Complete CMS control that allows you to upload and edit quickly
              and easily.
            </p>
            <p class="mt-2 w-90">
              See how your content will look as you create it. No need to keep
              reworking because of spacing issues or design constraints.
            </p>
          </div>
          <div
            class="col-12 col-lg-4 mt-5 mt-lg-0 p-0"
            data-aos="zoom-in-right"
          >
            <h2 class="h5 mb-5 fw-bold">Sophisticated WYSIWYG Content Editor</h2>
            <p class="mt-2 w-90">
              View content in the same format that your customers will see.
            </p>
            <p class="mt-2 w-90">
              Make content, format, and style changes directly in the editor.
            </p>
            <p class="mt-2 w-90">
              Drag and drop capabilities built into the editor allows for easy
              ordering of your content blocks.
            </p>
          </div>
          <div
            class="col-12 col-lg-4 mt-5 mt-lg-0 p-0"
            data-aos="zoom-in-right"
          >
            <h2 class="h5 mb-5 fw-bold">Future-Proof Architecture</h2>
            <p class="mt-2 w-90">
              Our fully decoupled architecture allows for lightning fast load
              times.
            </p>
            <p class="mt-2 w-90">
              Leverage JavaScript frameworks such as Vue, React, and Bootstrap
            </p>
            <p class="mt-2 w-90">
              Unify and manage content with native or progressive web apps,
              touchscreens or kiosks
            </p>
            <p class="mt-2 w-90">
              Engage and integrate with emerging technologies such as AI or IoT.
            </p>
          </div>
        </div>
      </section>
      <!-- Section 3 Columns -->

      <!-- Section - Atomic Chat  -->
      <section
        class="section-two-col pt-6 pb-3 mb-lg-0 mx-lg-auto section-atomic-app"
        id="section-atomic-chat"
      >
        <div class="row flex-column-reverse flex-md-row-reverse">
          <div
            class="d-flex flex-column align-content-start align-items-start align-items-md-end justify-content-lg-center col-sm-12 col-md-6 p-5 p-lg-6 p-xxl-7 pt-xxl-0"
            data-aos="zoom-in-left"
          >
            <h2 class="h6 font-prompt primary-color w-90 text-start">
              NEXT GEN CHATBOT
            </h2>
            <h3 class="display-6 mb-4 font-prompt mt-3 w-90 text-start">
              Atomic CHAT
            </h3>
            <p class="mt-1 mb-5 w-90">
              Nobody understands your customers like you do, that’s why we work
              directly with you to create a custom solution that fits your
              needs. Let Atomic Chat engage your passing web visitors and turn
              them into highly qualified leads. Atomic Chat can also handle your
              tier 1 and 2 support cases, and ties into your existing customer
              support tracking software.
            </p>
            <p class="mt-1 mb-5 w-90">
              With custom language sets and purposeful personas, that match your
              brand and your targeted audience – Atomic Chat will truly become
              your company’s new best friend.
            </p>
          </div>

          <div
            class="col-sm-12 col-md-6 bg-img bg-height p-0"
            :style="{
              backgroundImage:
                'url(' + require('@/assets/img-atomic-chat-full.png') + ')',
            }"
          >
            <img
              class="icon icon-overflow"
              src="@/assets/icon-edit.png"
              alt="Icon - app edit"
              data-aos="zoom-in-right"
               data-aos-duration="1000"
            />
          </div>
        </div>
      </section>
      <!-- Section - Atomic Chat -->

      <!-- Section - Leads  -->
      <section
        class="section-two-col pt-6 pb-3 mb-lg-0 mx-lg-auto mt-lg-6 section-atomic-app"
      >
        <div class="row flex-column-reverse flex-md-row">
          <div
            class="d-flex flex-column align-content-start align-items-start justify-content-lg-center col-sm-12 col-md-6 p-5 p-lg-6 p-xxl-7 pt-xxl-0"
            data-aos="zoom-in-left"
          >
            <h2 class="display-6 mb-4 font-prompt mt-3">
              Generate Quality Leads While You Sleep
            </h2>
            <p class="mt-1 w-90">
              At Atomic X, our chatbot can be deployed on-prem or it can be
              hosted in our secure Google Cloud environment.
            </p>
            <p class="mt-1 w-90">
              It can also be integrated with the chat engine of your choice -
              Dialogue Flow, Watson, Asure, or our own proprietary NLP engine.
              Collecting leads and generating quotes has never been easier.
            </p>
            <p class="mt-1 w-90">
              Streamline lead generation while increasing sales, reducing costs
              and providing a tailored customer service experience with our
              AI-powered qualified lead generation solution.
            </p>
          </div>

          <div
            class="col-sm-12 col-md-6 bg-img bg-height p-0"
            :style="{
              backgroundImage: 'url(' + require('@/assets/img-quality-leads.jpg') + ')',
            }"
          ></div>
        </div>
      </section>
      <!-- Section - Leads -->

      <!-- Section New Best Friend -->
      <section
        class="container-xxl my-auto mb-2 pb-5 pt-md-7 px-5 p-lg-6 mb-lg-4 px-xxl-6"
        data-aos="zoom-in-top"
      >
        <div
          class="row d-flex flex-column align-center align-items-center justify-content-center justify-content-md-between"
        >
          <h2
            class="d-block display-6 mb-4 font-prompt mt-3 text-center primary-color heading-max-width"
          >
            Atomic Chat is Your Business’ New Best Friend
          </h2>
          <h3 class="h5 fw-bold text-center">Brandable Workflow in a Nutshell</h3>
          <p class="mt-2 text-center">
            Be where the action is. Get insights to personalize the flow and
            paths of conversations to match your unique brand voice on any
            channel or device.
          </p>

          <div class="d-flex pt-md-6 flex-wrap justify-content-center">
            <div class="col-12 col-md-6 col-xl-4 mt-0" data-aos="zoom-in-right">
              <p class="mt-3 text-center px-3">
                <span class="icon-number">
                  1
                </span>
                The user initiates the conversation using a mobile device or a
                computer.
              </p>
            </div>

            <div
              class="col-12 col-md-6 col-xl-4 mt-5 mt-md-0"
              data-aos="zoom-in-right"
            >
              <p class="mt-3 text-center px-3">
                <span class="icon-number">2</span>
                Atomic Chat allows for convenient access for you and your
                customers across mobile platforms.
              </p>
            </div>

            <div class="col-12 col-md-6 col-xl-4 mt-0" data-aos="zoom-in-right">
              <p class="mt-3 text-center px-3">
                <span class="icon-number">
                  3
                </span>
                The bot goes through a series of questions in order to determine
                what the user is looking for - keeping it fast and simple.
              </p>
            </div>

            <div
              class="col-12 col-md-6 col-xl-4 mt-5 mt-md-0"
              data-aos="zoom-in-right"
            >
              <p class="mt-3 text-center px-3">
                <span class="icon-number">
                  4
                </span>
                You receive an instant notification with the ability to take
                over the chat or collect the lead for a later follow-up.
              </p>
            </div>

            
            <div
              class="col-12 col-md-6 col-xl-4 mt-5 mt-md-0"
              data-aos="zoom-in-right"
            >
              <p class="mt-3 text-center px-3">
                <span class="icon-number">
                  5
                </span>
                The bot retrieves all the information you need to easily jump in or follow-up at your convenience, allowing you to seamlessly acquire new customers or tend to current ones.
              </p>
            </div>
          </div>
        </div>
      </section>
      <!-- Section Section New Best Friend -->

      <!-- Section 2 Columns -->
      <section
        class="container-xxl my-auto mb-2 pb-5 pt-md-7 px-5 p-lg-6 mb-lg-4 px-xxl-6"
        data-aos="zoom-in-top"
      >
        <div
          class="row d-flex flex-row align-center align-items-start justify-content-center justify-content-md-between"
        >
          <div
            class="col-12 col-md-6 mt-0 py-0 pe-md-4"
            data-aos="zoom-in-right"
          >
            <h2 class="h5 mb-5 fw-bold">
            <img
              class="icon d-block mb-3"
              src="@/assets/icon-easy-set-up.svg"
              alt="Icon - Easy set-up"
            />
            Fast and Easy to Set Up</h2>
            <p class="mt-2 w-90">
              No disruption or re-design needed and it integrates into your
              existing site structure with ease. Our head-ache free set up means
              you’ll be up and generating leads in no-time.
            </p>
            <h2 class="h5 mt-5 mb-5 fw-bold">
              <img
                class="icon d-block mb-3"
                src="@/assets/icon-custom-language.svg"
                alt="Icon - custom language"
              />
              Chat Monitoring and Takeover Capabilities
            </h2>
            <p class="mt-2 w-90">
              Atomic Chat’s intuitive design notifies you when you’re needed in
              real-time. Monitor your current chats and join the conversation
              when it’s convenient for you.
            </p>
          </div>

          <div
            class="col-12 col-md-6 mt-5 mt-md-0 py-0 ps-md-4"
            data-aos="zoom-in-right"
          >
            <h2 class="h5 mb-5 fw-bold">
              <img
                class="icon d-block mb-3"
                src="@/assets/icon-chat-monitoring.svg"
                alt="Icon - chat monitoring"
              />
              Custom Language Sets That Appeal to Your Audience
            </h2>
            <p class="mt-2 w-90">
              We know that every customer counts. That’s why our bot allows for
              custom language sets that speak directly to your specific target
              audience.
            </p>
            <h2 class="h5 mt-5 mb-5 fw-bold ">
              <img
                class="icon d-block mb-3"
                src="@/assets/icon-custom-chat.svg"
                alt="Icon - custom chat"
              />
              Custom Chat Solutions For Any Size Business
            </h2>
            <p class="mt-2 w-90">
              Whether you’re running your own small business, or part of a large
              enterprise – Atomic Chat brings your unique brand and values to
              your customers, immediately. Atomic Chat responds to client
              requests as fast as they arrive. Atomic Chat collects information
              automatically and makes it a breeze to follow up. Real leads from
              real people that you can reach out to on your schedule.
            </p>
          </div>
        </div>
      </section>
      <!-- Section 3 Columns -->

      <!-- Section 4 Columns -->
      <section
        class="section-key-features container-xxl my-auto mb-2 pb-1 pt-md-4 px-5 p-lg-6 pb-lg-0 mb-lg-4 px-xxl-6"
        data-aos="zoom-in-top"
      >
        <div
          class="row d-flex flex-row align-center align-items-start align-items-lg-stretch justify-content-center justify-content-md-between"
        >
          <h2 class="h5 mb-5 mb-lg-7 fw-bold text-center">Key Features of Atomic Chat</h2>

          <div class="col-12 col-md-6 col-xl-3 mt-0 d-lg-flex flex-lg-column justify-content-lg-start align-content-lg-stretch align-items-lg-stretch" data-aos="zoom-in-right">
            <p class="mb-4 mb-lg-5 w-90" data-aos="zoom-in-right" data-aos-duration="800">
              <img
                class="icon d-block mb-3"
                src="@/assets/icon-lead-generation.svg"
                alt="Icon - app lead"
              />
              Simple lead generation and proven conversation methodologies
            </p>
            <p class="mb-4 mb-lg-5 w-90" data-aos="zoom-in-right" data-aos-duration="800">
              <img
                class="icon d-block mb-3"
                src="@/assets/icon-control.svg"
                alt="Icon - control"
              />
              Proprietary browser control *Patent pending
            </p>
            <p class="mb-4 mb-lg-5 w-90" data-aos="zoom-in-right" data-aos-duration="800">
              <img
                class="icon d-block mb-3"
                src="@/assets/icon-readiness.svg"
                alt="Icon - readiness"
              />
              Readiness use-case assessment available
              </p>
          </div>

          <div class="col-12 col-md-6 col-xl-3 mt-0 d-lg-flex flex-lg-column justify-content-lg-start align-content-lg-stretch align-items-lg-stretch" data-aos="zoom-in-right">
            <p class="mb-4 mb-lg-5 w-90" data-aos="zoom-in-right" data-aos-duration="800">
              <img
                class="icon d-block mb-3"
                src="@/assets/icon-eye.svg" 
                alt="Icon - eye"
              />
              Live monitoring of automated conversations
            </p>
            <p class="mb-4 mb-lg-5 w-90" data-aos="zoom-in-right" data-aos-duration="800">
              <img
                class="icon d-block mb-3"
                src="@/assets/icon-quick.svg"
                alt="Icon - connects"
              />
              Quick and hassle-free set-up</p>
            <p class="mb-4 mb-lg-5 w-90" data-aos="zoom-in-right" data-aos-duration="800">
               <img
                class="icon d-block mb-3"
                src="@/assets/icon-custom.svg"
                alt="Icon - connects"
              />
              Custom semantic clustering engine
            </p>
          </div>

          <div class="col-12 col-md-6 col-xl-3 mt-0 d-lg-flex flex-lg-column justify-content-lg-start align-content-lg-stretch align-items-lg-stretch" data-aos="zoom-in-right">
            <p class="mb-4 mb-lg-5 w-90" data-aos="zoom-in-right" data-aos-duration="800">
              <img
                class="icon d-block mb-3"
                src="@/assets/icon-bell.svg"
                alt="Icon - bell "
              />
              Real-time notifications with easy intervention using our app
            </p>
            <p class="mb-4 mb-lg-5 w-90" data-aos="zoom-in-right" data-aos-duration="800">
              <img
                class="icon d-block mb-3"
                src="@/assets/icon-connects.svg"
                alt="Icon - connects"
              />
              Connects to modern and legacy internal systems
            </p>
            <p class="mb-4 mb-lg-5 w-90" data-aos="zoom-in-right" data-aos-duration="800">
              <img
                class="icon d-block mb-3"
                src="@/assets/icon-quick.svg"
                alt="Icon - Clock"
              />
              Fast implementation time
            </p>
          </div>

          <div class="col-12 col-md-6 col-xl-3 mt-0 d-lg-flex flex-lg-column justify-content-lg-start align-content-lg-stretch align-items-lg-stretch" data-aos="zoom-in-right">
            <p class="mb-4 mb-lg-5 w-90" data-aos="zoom-in-right" data-aos-duration="800">
              <img
                class="icon d-block mb-3"
                src="@/assets/icon-sleep.svg"
                alt="Icon - sleep"
              />
              Custom language sets that can sell while you sleep
            </p>
            <p class="mb-4 mb-lg-5 w-90" data-aos="zoom-in-right" data-aos-duration="800">
              <img
                class="icon d-block mb-3"
                src="@/assets/icon-quick.svg"
                alt="Icon - Clock"
              />
              Cloud or on-site deployment
            </p>
            <p class="mb-4 mb-lg-5 w-90" data-aos="zoom-in-right" data-aos-duration="800">
              <img
                class="icon d-block mb-3"
                src="@/assets/icon-built-in.svg"
                alt="Icon - built-in"
              />
              Multi-user intervention capabilities with built in governance
              capabilities
            </p>
          </div>
        </div>
      </section>
      <!-- Section 4 Columns -->

      <!-- Section - Atomic App  -->
      <section
        class="section-two-col pt-6 pb-3 mb-lg-0 mx-lg-auto mt-lg-0 section-atomic-app"
        id="section-atomic-app"
      >
        <div class="row flex-column-reverse flex-md-row">
          <div
            class="d-flex flex-column align-content-start align-items-start justify-content-lg-center col-sm-12 col-md-6 p-5 p-lg-6 p-xxl-7 pt-xxl-0"
            data-aos="zoom-in-left"
          >
            <h2 class="display-6 mb-4 font-prompt mt-3">Atomic APP</h2>
            <p class="mt-1 mb-5 w-90">                  
              Book a demo to see under the hood of our best in-class app
              development tool designed to decrease development time by 75%.
              (Formally AppWorksOne)
            </p>
            <a href="/contact" class="btn-primary">Book Demo</a>
          </div>

          <div
            class="col-sm-12 col-md-6 bg-img bg-height p-0"
            :style="{
              backgroundImage:
                'url(' + require('@/assets/img-atomic-app-full.png') + ')',
            }"
          >
            <img
              class="icon icon-overflow"
              src="@/assets/icon-scroll.png"
              alt="Icon - app scrolling gesture"
              data-aos="zoom-in-right"
               data-aos-duration="1000"
            />
          </div>
        </div>
      </section>
      <!-- Section - Atomic App -->

      <!-- Section - Atomic DATA  -->
      <section
        class="section-two-col pt-6 pb-3 mb-lg-0 mx-lg-auto mt-lg-6 section-atomic-app"
        id="section-atomic-data"
      >
        <div class="row flex-column-reverse flex-md-row-reverse">
          <div
            class="d-flex flex-column align-content-start align-items-start align-items-md-end justify-content-lg-center col-sm-12 col-md-6 p-5 p-lg-6 p-xxl-7 pt-xxl-0"
            data-aos="zoom-in-left"
          >
            <h2 class="display-6 mb-4 font-prompt mt-3 w-90 text-start">
              Atomic DATA
            </h2>
            <p class="mt-1 mb-5 w-90">
              Book a demo to see the benefits of having a single login for all
              your data management and analysis.
            </p>
            <div class="w-90">
              <a href="/contact" class="btn-primary">Book Demo</a>
            </div>

            <!-- <p class="mt-1 mb-5 w-90">
              With custom language sets and purposeful personas, that match your brand and your targeted audience – Atomic Chat will truly become your company’s new best friend.
            </p> -->
          </div>

          <div
            class="col-sm-12 col-md-6 bg-img bg-height p-0"
            :style="{
              backgroundImage:
                'url(' + require('@/assets/img-atomic-data-full.jpg') + ')',
            }"
          >
            <img
              class="icon icon-overflow"
              src="@/assets/icon-data.png"
              alt="Icon - app data"
              data-aos="zoom-in-right"
               data-aos-duration="1000"
            />
          </div>
        </div>
      </section>
      <!-- Section - Atomic DATA -->

      <div class="col-12 d-none d-lg-block my-lg-7" style="height: 50px;"></div>
    </main>
  </div>
</template>

<script>
export default {
  name: "Products",
  route: {
    path: "/products",
    name: "AtomicX Products",
  },
  head: {
    title: {
      inner: "Atomic X | Toronto Artificial Intelligence Agency",
    },
    // Meta tags
    meta: [
      {
        name: "description",
        content:
          "AtomicX is Toronto&#039;s cutting edge artificial intelligence agency. A team of strategists, designers, and builders redefining today’s online marketplace.",
        id: "desc",
      },
    ],
    link: [
      {
        rel: "icon",
        href: require("../assets/favicon.png"),
        sizes: "32x132",
        type: "image/png",
      },
    ],
  },
};
</script>
