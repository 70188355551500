<template>
  <div>
    <main class="mx-auto">
      <!-- Section - Two Column  -->
      <section
        class="section-two-col pb-4 pb-lg-7 my-lg-5 mx-lg-auto bg-gradient"
      >
        <div class="row flex-column-reverse flex-md-row">
          <div
            class="col-sm-12 col-md-6 p-5 p-lg-6 p-xxl-7"
            data-aos="zoom-in-right"
          >
            <h1 class="display-5 mb-4 font-prompt">
              404 - Not Found
            </h1>
          </div>

          <div class="col-sm-12 col-md-6 bg-img bg-jumbotron p-0">
            <div
              class="img-with-icon mt-0"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/img-home-jumbotron.png') + ')',
              }"
              data-aos="zoom-in-left"
            ></div>
          </div>
        </div>
      </section>
      <!-- Section - Two Column -->
    </main>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  route: {
    path: "/notFound",
    name: "AtomicX NotFound",
  },
  head: {
    title: {
      inner: "Atomic X | Toronto Artificial Intelligence Agency",
    },
    // Meta tags
    meta: [
      {
        name: "description",
        content:
          "AtomicX is Toronto&#039;s cutting edge artificial intelligence agency. A team of strategists, designers, and builders redefining today’s online marketplace.",
        id: "desc",
      },
    ],
    link: [
      {
        rel: "icon",
        href: require("../assets/favicon.png"),
        sizes: "32x132",
        type: "image/png",
      },
    ],
  },
};
</script>
