<template>
  <div>
    <main class="mx-auto">
      <Placeholder />

      <div class="d-none d-lg-block mb-7" style="height: 200px;"></div>
    </main>
  </div>
</template>

<script>
export default {
  name: "News",
  route: {
    path: "/news",
    name: "AtomicX News",
  },
};
</script>
