<template>
  <div>
    <main class="mx-auto">
      <!-- Section - Contact  -->
      <section
        class="
          section-two-col
          contact-page-form
          container-xxl
          mx-lg-auto
          mt-lg-7
        "
      >
        <div class="row flex-column flex-lg-row pb-6">
          <div
            class="
              d-flex
              flex-column
              align-content-start align-items-start
              justify-content-lg-end
              col-sm-12 col-md-12 col-lg-6
              p-5 p-lg-6 p-xxl-7
            "
          >
            <h2 class="display-6 font-prompt mb-5">Get In Touch</h2>
            <form
              action="https://us-central1-atx-emails.cloudfunctions.net/emails/formPost"
              method="POST"
              class="d-flex flex-column w-100"
            >
              <input
                type="hidden"
                name="redirectTo"
                value="https://atomicx.ai/thanks"
              />
              <input type="hidden" name="client" value="Atomic X" />
              <input
                type="hidden"
                name="mailTo"
                value="alexandre@atomicmotion.com, dan@atomicmotion.com"
              />
              <input type="hidden" name="URL:" value="https://atomicx.ai" />
              <div class="form-group mb-3">
                <label for="form-name" class="mb-1">Name</label>
                <input
                  type="text"
                  class="form-control bg-white text-dark"
                  id="form-name"
                  name="name"
                  placeholder="Enter your name"
                />
              </div>
              <div class="form-group mb-3">
                <label for="form-company" class="mb-1">Company</label>
                <input
                  type="text"
                  class="form-control bg-white text-dark"
                  id="form-company"
                  name="company name"
                  placeholder="Enter your company name"
                />
              </div>
              <div class="form-group mb-3">
                <label for="from-email" class="mb-1">Email</label>
                <input
                  type="email"
                  class="form-control bg-white text-dark"
                  id="from-email"
                  name="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter your email"
                />
              </div>
              <label for="select-interest" class="mb-1">Interested in?</label>

              <select
                class="form-control form-select bg-white text-dark"
                id="select-interest"
                name="interested in"
              >
                <option>Choose one</option>
                <option value="cms">CMS</option>
                <option>Chat</option>
                <option>App</option>
                <option>Data</option>
              </select>

              <button type="submit" class="btn btn-primary mt-5">Submit</button>
            </form>
          </div>

          <div
            class="
              d-flex
              flex-column
              align-content-center align-items-center
              justify-content-center
              col-sm-12 col-md-12 col-lg-6
              mt-5 mt-md-0
              ps-lg-7
            "
          >
            <div
              class="w-90 mb-5 pb-5"
              style="border-bottom: 1px solid #d8d8d8"
            >
              <h2 class="h4 primary-color mb-4 text-start">Ottawa</h2>
              <p class="h5">
                World Exchange Plaza, 3rd floor <br />
                111 Albert St #300 <br />
                Ottawa, ON <br />
                K1P 1A5
              </p>
              <p class="h5 mt-4 primary-color">613-266-8841</p>
            </div>

            <div class="w-90">
              <h2 class="h4 primary-color mb-4 text-start">Toronto</h2>
              <p class="h5">
                545 King Street West<br />
                Toronto, ON M5V 1M1<br />
              </p>
              <p class="h5 mt-4 primary-color">416-556-2077</p>
            </div>
          </div>
        </div>
      </section>
      <!-- Section - Contact -->
    </main>
  </div>
</template>

<script>
export default {
  name: "contact",
  route: {
    path: "/contact",
    name: "AtomicX Contact",
  },
  head: {
    title: {
      inner: "Atomic X | Toronto Artificial Intelligence Agency",
    },
    // Meta tags
    meta: [
      {
        name: "description",
        content:
          "AtomicX is Toronto&#039;s cutting edge artificial intelligence agency. A team of strategists, designers, and builders redefining today’s online marketplace.",
        id: "desc",
      },
    ],
    link: [
      {
        rel: "icon",
        href: require("../assets/favicon.png"),
        sizes: "32x132",
        type: "image/png",
      },
    ],
  },
};
</script>
