<template>
  <div>
    <main class="mx-auto">
      <!-- Section - Two Column  -->
      <section
        class="section-two-col pb-4 pb-lg-7 my-lg-5 mx-lg-auto bg-gradient"
      >
        <div class="row flex-column-reverse flex-md-row">
          <div
            class="col-sm-12 col-md-6 p-5 p-lg-6 p-xxl-7"
          >
            <h1 class="display-5 mb-4 font-prompt">
              Complete digital management that
              <span class="primary-color">transforms</span> your business.
            </h1>
            <p class="mt-1 mt-lg-7 mb-5">
              Atomic X gives you the power to build and manage every aspect of
              your digital experience from a single centralized platform.
            </p>
            <div
              class="
                d-flex
                flex-column flex-sm-row flex-wrap
                align-items-center
                justify-content-sm-start
              "
            >
              <a href="/products" class="btn-primary me-sm-3 mb-2">Get Started</a>
              <a href="/contact" class="btn-outline-primary mt-2 mt-sm-0 mb-2"
                >Learn More</a
              >
            </div>
          </div>

          <div class="col-sm-12 col-md-6 bg-img bg-jumbotron p-0">
            <div
              class="img-with-icon mt-0"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/img-home-jumbotron.png') + ')',
              }"
            ></div>
          </div>
        </div>
      </section>
      <!-- Section - Two Column -->

      <div
        class="
          container-xxl
          d-flex
          flex-column
          align-content-center align-items-center
          justify-content-center
          p-5
          py-lg-7
          px-lg-7
          light-blue
        "
        data-aos="slide-up"
      >
        <div class="container wrapper-lg">
          <h2 class="display-6 text-center mb-5 font-prompt">
            Atomic X gives you the power to build and manage every aspect of
            your digital experience from a
            <span class="primary-color">single centralized platform.</span>
          </h2>

          <p class="h5 mb-5 text-center">
            Today’s CMS landscape is a disconnected set of independent tools and
            systems that are disjointed, and held together with ‘duct tape’
            code. We decided to build a set of tools in a unified, easy to use
            platform, Atomic X.
          </p>

          <p class="h5 text-center">
            Atomic X is like taking Wordpress, Native App Development, and Live
            and Automated Chat and combining them into a single easy to use
            solution.
          </p>
        </div>
      </div>

      <!-- Red Shape background -->
      <div class="container-xl bg-red-shape pt-lg-7 pb-lg-7">
        <!-- Section - Two Column  -->
        <section class="section-two-col my-lg-0 mx-lg-auto">
          <div class="row flex-column-reverse flex-md-row-reverse">
            <div
              class="
                d-flex
                flex-column
                align-content-start align-items-start
                justify-content-lg-center
                col-sm-12 col-md-6
                p-5 p-lg-6 p-xxl-7
                pt-xxl-0
                bg-primary-color
              "
              data-aos="zoom-in-left"
            >
              <h2 class="h5 font-prompt text-white">Atomic CMS</h2>
              <h3 class="display-6 mb-4 font-p mt-3rompt text-white">
                A True Headless CMS
              </h3>
              <p class="mt-1 mb-5 text-white">
                CMS offers complete flexibility and integration with existing
                applications or tools plus complete interconnectivity with any
                API on the market. Imagine complete freedom for marketers, built
                by and for developers that offers your customers a personalized
                experience that will build your brand and your bottomline.
              </p>
              <a href="/products#section-atomic-cms" class="btn-primary icon-arrow text-white"
                >More Info</a
              >
            </div>

            <div class="col-sm-12 col-md-6 bg-img p-0">
              <div
                class="img-with-icon"
                :style="{
                  backgroundImage:
                    'url(' + require('@/assets/img-atomic-cms.png') + ')',
                }"
                data-aos="zoom-in-up"
              >
                <img
                  src="@/assets/icon-cms.svg"
                  alt="Icon - editing tool"
                  data-aos="zoom-in-right"
                  data-aos-duration="600"
                />
              </div>
            </div>
          </div>
        </section>
        <!-- Section - Two Column -->

        <!-- Section - Two Column  -->
        <section class="section-two-col my-lg-0 mx-lg-auto">
          <div class="row flex-column-reverse flex-md-row">
            <div
              class="
                d-flex
                flex-column
                align-content-start align-items-start
                justify-content-lg-center
                col-sm-12 col-md-6
                p-5 p-lg-6 p-xxl-7
                pt-xxl-0
              "
              data-aos="zoom-in-right"
            >
              <h2 class="h5 font-prompt text-white">Atomic CHAT</h2>
              <h3 class="display-6 mb-4 font-p mt-3rompt text-white">
                The Next Gen Chatbot
              </h3>
              <p class="mt-1 mb-5 text-white">
                Think of chat as a customer relationship manager that can chat
                with 1000 people at a time. It knows the details about your
                products or services, and can deliver your brand's message in a
                fun and engaging manner. Atomic CHAT turns your website into a
                customer conversation. We use a proprietary blend of curated
                language sets and state of the art machine learning to engage
                customers more intelligently than ever.
              </p>
              <a href="/products#section-atomic-chat" class="btn-primary icon-arrow text-white"
                >More Info</a
              >
            </div>

            <div class="col-sm-12 col-md-6 bg-img p-0">
              <div
                class="img-with-icon"
                :style="{
                  backgroundImage:
                    'url(' + require('@/assets/img-atomic-chat.png') + ')',
                }"
                data-aos="zoom-in-up"
              >
                <img
                  class="icon-reversed"
                  src="@/assets/icon-chat.svg"
                  alt="Icon - chatting bubbles"
                  data-aos="zoom-in-left"
                  data-aos-duration="600"
                />
              </div>
            </div>
          </div>
        </section>
        <!-- Section - Two Column -->
      </div>
      <!-- Red Shape Background -->

      <!-- Section - Two Column  -->
      <section
        class="section-two-col pt-6 pb-3 mb-lg-0 mx-lg-auto section-atomic-app"
      >
        <div class="row flex-column-reverse flex-md-row-reverse">
          <div
            class="
              d-flex
              flex-column
              align-content-start align-items-start
              justify-content-lg-center
              col-sm-12 col-md-6
              p-5 p-lg-6 p-xxl-7
              pt-xxl-0
            "
            data-aos="zoom-in-left"
          >
            <h2 class="h5 font-prompt primary-color">Atomic APP</h2>
            <h3 class="display-6 font-prompt mt-3 mb-4">
              Unify Your Customer’s Digital Experience.
            </h3>
            <p class="mt-1 mb-5">
              APP gives your team the power to manage your entire digital
              offering from a single dashboard. APP also allows you to deploy a
              dedicated app customer experiences in a fraction of the time of
              traditional app development technologies. Acting as a progressive
              web-app with a prebuilt API-first infrastructure, APP allows your
              developers to quickly and easily integrate dedicated app
              experiences with a collection of prebuilt, ready to go features
              such as personalization, geo-targeting and membership management.
            </p>
            <a href="/products#section-atomic-app" class="btn-primary icon-arrow">More Info</a>
          </div>

          <div class="col-sm-12 col-md-6 bg-img p-0">
            <div
              class="img-with-icon"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/img-atomic-app.png') + ')',
              }"
              data-aos="zoom-in-up"
            >
              <img
                src="@/assets/icon-app.svg"
                alt="Icon - app scrolling gesture"
                data-aos="zoom-in-right"
              />
            </div>
          </div>
        </div>
      </section>
      <!-- Section - Two Column -->

      <!-- Section - Two Column  -->
      <section
        class="section-two-col section-join py-0 px-0 mt-0 my-lg-5 mb-lg-7"
      >
        <div class="row flex-column-reverse flex-md-row">
          <div
            class="
              d-flex
              flex-column
              align-content-start align-items-start
              justify-content-lg-center
              col-sm-12 col-md-6
              p-5 p-lg-6 p-xxl-7
              bg-white
            "
          >
            <h2
              class="display-5 font-prompt mt-4 mb-4 text-primary"
              data-aos="zoom-in-down"
            >
              Join Atomic X
            </h2>
            <p class="mt-2 mb-4 h5" data-aos="zoom-in-down">
              Join a highly skilled team of AI Strategists, Computer Engineers,
              Data Scientists and UX Specialists, Atomic X offers a competitive
              salary, full benefits, a generous stock-option plan and a kick-ass
              work environment with flexible work-from-home options. We are
              currently looking to fill the following positions:
            </p>
            <ul
              class="d-flex flex-column list list-icons-circle-arrow"
              data-aos="zoom-in-down"
            >
              <li><a href="/careers?select=Senior Front-End Developer">Senior Front-End Developer</a></li>
              <li>
                <a href="/careers?select=Senior sales executive"
                  >Senior sales executive
                  <span>(Government and Education)</span></a
                >
              </li>
              <li><a href="/careers?select=Customer Success Manager">Customer success manager</a></li>
            </ul>
          </div>

          <div
            class="col-sm-12 col-md-6 bg-img p-0"
            :style="{
              backgroundImage:
                'url(' + require('@/assets/img-join-atomic.png') + ')',
            }"
          >
            <div
              class="logo-box"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/logo-atomic-x-white.png') + ')',
              }"
              data-aos="zoom-in-up"
            ></div>
          </div>
        </div>
      </section>
      <!-- Section - Two Column -->

      <!-- Section - In The News -->
      <!-- <div
        class="section-news d-flex flex-column align-content-center align-items-center justify-content-center py-5 px-4 px-lg-7"
      >
        <div class="">
          <h2
            class="display-6 font-prompt text-center mb-5 primary-color"
            data-aos="zoom-in-up"
          >
            In The News
          </h2>

          <div
            class="wrapper d-flex flex-column flex-md-row align-items-md-stretch justify-content-md-between"
          >
            <div class="col-md-4 px-md-3 mb-5 mt-lg-5" data-aos="zoom-in-down">
              <div class="col-news d-flex flex-column">
                <div
                  class="news-img"
                  :style="{
                    backgroundImage:
                      'url(' + require('@/assets/img-news-1.jpg') + ')',
                  }"
                >
                  <p>Ai</p>
                  <p>Chatbot</p>
                </div>
                <h3 class="mt-4 mx-4">
                  Ottawa’s Atomic X puts personal touch on chatbots
                </h3>
                <a href="#" class="btn-primary icon-arrow m-4">Read More</a>
              </div>
            </div>

            <div class="col-md-4 px-md-3 mb-5 mt-lg-5" data-aos="zoom-in-down">
              <div class="col-news d-flex flex-column">
                <div
                  class="news-img"
                  :style="{
                    backgroundImage:
                      'url(' + require('@/assets/img-news-2.jpg') + ')',
                  }"
                >
                  <p>Ai</p>
                </div>
                <h3 class="mt-4 mx-4">
                  AI assistants with a touch of humanity poised to win over
                  customers
                </h3>
                <a href="#" class="btn-primary icon-arrow m-4">Read More</a>
              </div>
            </div>

            <div class="col-md-4 px-md-3 mb-5 mt-lg-5" data-aos="zoom-in-down">
              <div class="col-news d-flex flex-column">
                <div
                  class="news-img"
                  :style="{
                    backgroundImage:
                      'url(' + require('@/assets/img-news-3.jpg') + ')',
                  }"
                >
                  <p>Ai</p>
                  <p>Alexa</p>
                </div>
                <h3 class="mt-4 mx-4">Learning From Alexa’s Mistakes</h3>
                <a href="#" class="btn-primary icon-arrow m-4">Read More</a>
              </div>
            </div>
          </div>

          <a href="#" class="btn-primary mt-lg-5 mx-auto" data-aos="zoom-in-up"
            >View All</a
          >
        </div>
      </div> -->
      <!-- Section - In The News -->

      <!-- Section - Contact  -->
      <section
        class="
          section-two-col section-get-in-touch
          container-xxl
          mx-lg-auto
          bg-darker-grey
        "
      >
        <div class="row flex-column flex-lg-row pb-6">
          <div
            class="
              d-flex
              flex-column
              align-content-start align-items-start
              justify-content-lg-end
              col-sm-12 col-md-12 col-lg-6
              p-5 p-lg-6 p-xxl-7
            "
            data-aos="zoom-in-down"
          >
            <h2 class="display-6 font-prompt mb-5 primary-color">
              Get In Touch
            </h2>
              <form
              action="https://us-central1-atx-emails.cloudfunctions.net/emails/formPost"
              method="POST"
              class="d-flex flex-column w-100"
            >
              <input
                type="hidden"
                name="redirectTo"
                value="https://atomicx.ai/thanks"
              />
              <input type="hidden" name="client" value="Atomic X" />
              <input
                type="hidden"
                name="mailTo"
                value="alexandre@atomicmotion.com, dan@atomicmotion.com"
              />
               <input
                type="hidden"
                name="URL:"
                value="https://atomicx.ai"
              />
              <div class="form-group mb-3">
                <label for="form-name" class="mb-1">Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="form-name"
                  name="name"
                  placeholder="Enter your name"
                />
              </div>
              <div class="form-group mb-3">
                <label for="form-company" class="mb-1">Company</label>
                <input
                  type="text"
                  class="form-control"
                  id="form-company"
                  name="company name"
                  placeholder="Enter your company name"
                />
              </div>
              <div class="form-group mb-3">
                <label for="from-email" class="mb-1">Email</label>
                <input
                  type="email"
                  class="form-control"
                  id="from-email"
                  name="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter your email"
                />
              </div>
              <label for="select-interest" class="mb-1">Interested in?</label>

              <select
                class="form-control form-select text-dark"
                id="select-interest"
                name="interested in"
              >
                <option>Choose one</option>
                <option value="cms">CMS</option>
                <option>Chat</option>
                <option>App</option>
                <option>Data</option>
              </select>

              <button type="submit" class="btn btn-primary mt-5">Submit</button>
            </form>
          </div>

          <div
            class="
              d-flex
              flex-column
              align-content-center align-items-center
              justify-content-center
              col-sm-12 col-md-12 col-lg-6
              mt-5 mt-md-0
            "
          >
            <img
              class="mb-5"
              src="@/assets/icon-location.svg"
              alt="Icon Pin Locationb"
              data-aos="fade-up"
              data-aos-duration="1000"
            />
            <p class="h4 text-white text-center">
              World Exchange Plaza, 3rd floor <br />
              111 Albert St #300 <br />
              Ottawa, ON <br />
              K1P 1A5
            </p>
          </div>
        </div>
      </section>
      <!-- Section - Contact -->
    </main>
  </div>
</template>

<script>
export default {
  name: "home",
  // route: {
  //   path: "/",
  //   name: "AtomicX Home",
  // },
  head: {
    title: {
      inner: "Atomic X | Toronto Artificial Intelligence Agency",
    },
    // Meta tags
    meta: [
      {
        name: "description",
        content:
          "AtomicX is Toronto&#039;s cutting edge artificial intelligence agency. A team of strategists, designers, and builders redefining today’s online marketplace.",
        id: "desc",
      },
    ],
    link: [
      {
        rel: "icon",
        href: require("../assets/favicon.png"),
        sizes: "32x132",
        type: "image/png",
      },
    ],
  },
};
</script>
