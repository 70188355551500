<template>
  <div id="app">
    <!-- Header Start -->
    <SiteHeader />

    <!-- Header End -->

    <!-- <router-view /> can be commented out and panels writter right here -->
    <div class="route-wrapper" v-show="render">
      <!-- <transition name="slide-fade"> -->
      <router-view />
      <!-- </transition> -->
    </div>
    <!-- Footer Start -->
    <SiteFooter v-show="render" />
    <!-- Footer End -->
  </div>
</template>

<script>
// To simplify development bootstrap js files are installed on the public/index.html file (using CDN)
export default {
  name: "App",
  data() {
    return {
      header: [
        // INSERTS EXTRA SCRIPTS IN <head></head> Just before </head>  See example below.
        // THIS IS NOW REPLACED BY JUST PUTTING REQUIRED SCRIPTS IN public/index.html file
      ],
      footer: [
        // INSERTS EXTRA SCRIPTS IN <body></body> Just before </body>. See example below.
        // THIS IS NOW REPLACED BY JUST PUTTING REQUIRED SCRIPTS IN public/index.html file
      ],
      showHide: {
        rightDrawer: false,
      },
      render: true,
      scrollY: 0,
    };
  },
  head: {
    title: {
      inner: "Atomic X | Toronto Artificial Intelligence Agency",
    },
    // Meta tags
    meta: [
      {
        name: "description",
        content:
          "AtomicX is Toronto&#039;s cutting edge artificial intelligence agency. A team of strategists, designers, and builders redefining today’s online marketplace.",
        id: "desc",
      },
    ],
    link: [
      {
        rel: "icon",
        href: require("./assets/favicon.png"),
        sizes: "32x132",
        type: "image/png",
      },
    ],
  },
  watch: {
    /* eslint-disable */
    $route(to, from) {
      this.showHide.rightDrawer = false;
    },
  },
  computed: {
    routes() {
      let routes = this.$router.getRoutes();
      routes.sort(function (a, b) {
        if (a.name == "Main Page" || b.name == "Main Page") {
          return 0;
        }
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      return routes;
    },
  },
  methods: {
    initScroll() {
      this.scrollCheck();
      window.addEventListener("scroll", this.scrollCheck);
    },
    destroyScroll() {
      // console.log("remove check");
      window.removeEventListener("scroll", this.scrollCheck);
    },
    scrollCheck() {
      //console.log("scrollcheck");
      if (window.scrollY == 0) {
        this.render = true;
        this.destroyScroll();
      }
    },
  },
  created() {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.showHide.rightDrawer = !this.showHide.rightDrawer;
      }
    });
    this.$router.beforeEach((to, from, next) => {
      this.render = false;
      next();
    });
  },
  updated() {
    this.$nextTick(() => {
      window.scrollTo(0, 0);
      this.initScroll();
      AOS.refresh();
      //console.log("updated");
      // Even this fires before all images are fully rendered
    });
  },
};
</script>

<style lang="scss">
@import "@/scss/custom.scss";
.scroll {
  position: absolute;
  top: 35;
  left: 20;
}
</style>

<style lang="scss" scoped>
// styles specifically for this starter app
.route-wrapper {
  position: relative;
  overflow: hidden;
  header {
    // transform: translateY(56px) !important;
    top: 56px !important;
  }
  // > div {
  //   // margin-top: 36px !important;
  // }
}
.drawer-btn {
  position: fixed;
  right: 0;
  i {
    font-size: 28px;
    color: white;
    transition: opacity 0.25s ease-in-out;
    &:hover {
      opacity: 0.8;
    }
  }
}

.right-drawer {
  $width: 300px;

  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  height: 100%;
  width: $width;
  transform: translateX($width);
  transition: all 0.25s ease-in-out;

  .drawer-close-btn {
    position: absolute;
    background: white;
    border-radius: 0;
    padding: 0px 4px;
    left: -20px;
    top: 0px;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
    -webkit-box-shadow: -5px 0px 8px -5px rgba(0, 0, 0, 0.49);
    box-shadow: -5px 0px 8px -5px rgba(0, 0, 0, 0.49);
  }

  &.open {
    transform: translateX(0);

    .drawer-close-btn {
      opacity: 1;
    }
  }
}
</style>
