// CENTRALIZED IMPORT FILE INDEX FOR ROUTES / STORE(VUEX)

/* eslint-disable */

// EXPORT ALL STORE (VUEX) MODULES SO IT CAN BE IMPORTED AS SINGLE OBJECT
const StoreContext = require.context('./', true, /\.store\.js$/); // gets all files that ends in .store.js
const _Stores = {};

StoreContext.keys().forEach(filePath => {
    // create the module name from filePath
    // uses module name (if exists, if not will try and get it from the filename - Contacts.store.js will be Contacts)
    const store = StoreContext(filePath).default;
    const storeName = store.name || filePath.split("/").pop().split(".")[0]
    store.namespaced = true // Ensures that each store is namespaced
    _Stores[storeName] = StoreContext(filePath).default || StoreContext(filePath);
});

let _Views = []
const ViewsContext = require.context("./", true, /\.(js|vue)$/i); // where 'true' means to look in all subfolders
ViewsContext.keys().map((key) => {

    const name = key.match(/\w+/)[0]; // creates comp name from file name
    const comp = ViewsContext(key).default
    //console.log('view name', name)

    try {
        // check if route key exists push into route
        if (comp.route) {
            let route = {
                path: comp.route.path,
                name: comp.route.name || name,
                component: comp
            }
            _Views.push(route)

        }
    } catch (error) {
        //console.log('err', error, name)
    }
});

export const Views = _Views
export const Stores = _Stores

export default { Stores, Views }